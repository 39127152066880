import {
    DATA_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    FETCH_CALENDAR_APPOINTMENT,
    FETCH_CALENDAR_APPOINTMENT_SUCCESS,
    CODE_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_SAVE_COMMON,
    SURVEY_SCREEN_LOAD,
} from '../types';
import * as helpers from '../../utils/helpers';
import WordingConstant from '../../utils/wording';

const Wording = WordingConstant.DataScreen;

export const initialState = {
    common: {
        situation: '',
        civility: 'MR',
        name: '',
        surname: '',
        birthdate: '',
        email: '',
        tel: '',
        address: {
            address: '',
            postalCode: '',
            city: '',
            netArea: '',
        },
        newAddress: {
            address: null,
            postalCode: null,
            city: null,
            netArea: null,
        },
        newBillingAddress: {
            civility: 'MR',
            name: null,
            surname: null,
            address: null,
            postalCode: null,
            city: null,
            netArea: null,
        },
        dateContract: {
            EL: {
                date: null,
                month: null,
                year: null,
                express: false,
                timeslot: '',
                timeslots: [],
            },
            NG: {
                date: null,
                month: null,
                year: null,
                express: false,
                timeslot: '',
                timeslots: [],
            },
            switch: null,
        },
    },
    part: {
        coOwner: {
            civility: 'MR',
            firstName: '',
            lastName: '',
        },
    },
    pro: {
        socialReason: '',
        legalForm: '',
        APECode: '',
        SIRET: '',
    },
    calendarAppointments: [],
    modifiedAddress: false,
    finance: undefined,
};

const recoverState = (state, order) => {
    const {
        customer: { contact, finance },
        contracts,
    } = order;
    // Remove +33 and add 0

    const tel = helpers.displayPhone(contact.phone);
    const recoverBirthdate = new Date(contact.birthdate).toDateString();
    const common = {
        tel,
        civility: contact.civility,
        name: contact.firstName,
        surname: contact.lastName,
        birthdate: recoverBirthdate,
        email: contact.email,
    };
    const part = {
        coOwner: {
            civility: 'MR',
            firstName: '',
            lastName: '',
        },
    };

    const { billingContact } = finance;
    if (billingContact) {
        let billingAddress = billingContact.address.street;
        if (billingContact.address.number) {
            billingAddress = `${
                billingContact.address.number
            } ${billingAddress}`;
        }
        common.newBillingAddress = {
            civility: billingContact.civility,
            name: billingContact.firstName,
            surname: billingContact.lastName,
            address: billingAddress,
            postalCode: billingContact.address.postalCode,
            city: billingContact.address.townName,
            netArea: billingContact.address.netArea,
        };
    }
    if (contracts.length > 0) {
        common.situation = contracts[0].deliveryPoint.process.type;
        const thirdParty =
            (contracts[0].thirdParties &&
                contracts[0].thirdParties.find(tp => tp.role === 'COHOLDER')) ||
            {};
        part.coOwner = {
            civility: thirdParty.civility || 'MR',
            firstName: thirdParty.firstName || '',
            lastName: thirdParty.lastName || '',
        };
    }
    if (contact.address) {
        let address = contact.address.street;
        if (contact.address.number) {
            address = `${contact.address.number} ${address}`;
        }
        const modifiedAddress = {
            address,
            postalCode: contact.address.postalCode,
            netArea: contact.address.netArea,
            city: contact.address.townName,
        };
        common.address = modifiedAddress;
    }
    const pro = {
        socialReason: contact.companyName || '',
        legalForm: contact.companyType || '',
        APECode: contact.activityCode || '',
        SIRET: contact.identificationNumber || '',
    };
    if (contracts) {
        common.dateContract = { EL: {}, NG: {} };
        contracts.forEach(contract => {
            const date = new Date(contract.effectiveStartDate);
            const timeslot =
                contract.deliveryPoint.process.appointmentTimeslot || '';
            const express = contract.deliveryPoint.process.express || false;
            common.dateContract[contract.energy] = {
                timeslot,
                express,
                date: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            };
            if (
                common.situation ===
                Wording.situation.values.changeSupplier.value
            ) {
                common.dateContract.switch = Wording.contractDate.dropdownValues.find(
                    v => v.value === contract.effectiveStartRange
                );
            }
        });
    }
    return {
        ...state,
        common: {
            ...state.common,
            ...common,
        },
        part,
        pro,
        finance,
    };
};

const updateAddress = (state, address) => {
    if (!address) {
        return {
            ...state,
        };
    }
    let fullAddress = `${address.street || ''}`;
    if (address.number) {
        fullAddress = `${address.number} ${fullAddress}`;
    }
    const modifiedAddress = {
        address: fullAddress,
        postalCode: address.postalCode || '',
        city: address.townName || '',
        netArea: address.netArea,
    };

    return {
        ...state,
        common: {
            ...state.common,
            address: modifiedAddress,
        },
    };
};

function dataReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_SCREEN_VALIDATE_SUCCESS:
        case DATA_SCREEN_VALIDATE_SUCCESS_FINAL:
            return {
                ...state,
                common: action.payload.common,
                part: action.payload.part,
                pro: action.payload.pro,
            };
        case FETCH_CALENDAR_APPOINTMENT:
            return {
                ...state,
            };
        case FETCH_CALENDAR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                calendarAppointments: [...action.payload],
            };
        case SURVEY_SCREEN_LOAD: {
            const { contracts } = action.payload;
            const { address } = contracts[0].deliveryPoint;
            return updateAddress(
                {
                    ...state,
                    modifiedAddress: true,
                },
                address
            );
        }
        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const { contracts } = action.payload;
            const { address } = contracts[0].deliveryPoint;
            if (state.modifiedAddress) {
                return {
                    ...state,
                    modifiedAddress: false,
                };
            }
            return updateAddress(state, address);
        }
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS:
        case CODE_SCREEN_VALIDATE_SUCCESS: {
            const { contracts } = action.payload;
            const { address } = contracts[0].deliveryPoint;
            return updateAddress(state, address);
        }
        case MAIN_SCREEN_GET_ORDER_SUCCESS: {
            const { order } = action.payload;
            return recoverState(state, order);
        }
        case DATA_SCREEN_SAVE_COMMON: {
            return {
                ...state,
                common: {
                    ...action.payload,
                },
            };
        }
        default:
            return state;
    }
}

export default dataReducer;
