import React from 'react';

import UserScreen from './UserScreen';
import CodeScreen from './CodeScreen';
import SurveyScreen from './SurveyScreen';
import SummaryScreen from './SummaryScreen';
import PackageScreen from './PackageScreen';
import DataScreen from './DataScreen';
import FinalSummaryScreen from './FinalSummaryScreen';
import PaymentScreen from './PaymentScreen';
import FinalScreen from './FinalScreen';
import SaveScreen from './SaveScreen';
import Screen from './screen';

export default function NavigationController({ type, ...props }) {
    switch (type) {
        case Screen.USER:
            return <UserScreen {...props} />;
        case Screen.CODE:
            return <CodeScreen {...props} />;
        case Screen.SURVEY:
            return <SurveyScreen {...props} />;
        case Screen.SUMMARY:
            return <SummaryScreen {...props} />;
        case Screen.PACKAGE:
            return <PackageScreen {...props} />;
        case Screen.DATA:
            return <DataScreen {...props} />;
        case Screen.FINALSUMMARY:
            return <FinalSummaryScreen {...props} />;
        case Screen.PAYMENT:
            return <PaymentScreen {...props} />;
        case Screen.FINAL:
            return <FinalScreen {...props} />;
        case Screen.SAVE:
            return <SaveScreen {...props} />;
        default:
            return <div />;
    }
}
