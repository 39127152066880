import {
    PAYMENT_SCREEN_LOAD,
    PAYMENT_SCREEN_VALIDATE,
    PAYMENT_SCREEN_VALIDATE_SUCCESS,
    PAYMENT_SCREEN_VALIDATE_FAILURE,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
} from '../types';

export const initialState = {
    reference: '',
    externalReference: '',
    iframe: null,
    status: '',
};

function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case PAYMENT_SCREEN_LOAD: {
            const regex = /<iframe.*?src="(.*?)"/;
            const res = regex.exec(action.payload.iframe);
            const iframe = res ? res[1] : null;
            return {
                ...state,
                iframe,
                reference: action.payload.orderNumber,
                externalReference: action.payload.orderExternalReference,
                status: '',
            };
        }
        case PAYMENT_SCREEN_VALIDATE:
            return {
                ...state,
            };
        case PAYMENT_SCREEN_VALIDATE_SUCCESS:
            return {
                ...state,
                status: action.payload.orderStatus,
                iframe: null,
            };
        case PAYMENT_SCREEN_VALIDATE_FAILURE:
            return {
                ...state,
                reference: '',
                externalReference: '',
                iframe: null,
                status: '',
            };
        case MAIN_SCREEN_GET_ORDER_SUCCESS: {
            const { order } = action.payload;
            if (order.orderStatus === 'IN_PROGRESS') {
                return {
                    ...state,
                    reference: order.orderReference,
                    status: order.orderStatus,
                };
            }
            return {
                ...state,
            };
        }
        default:
            return state;
    }
}

export default paymentReducer;
