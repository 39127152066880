import React from 'react';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Common;

const PackageElement = ({
    title,
    price,
    description,
    chosen,
    recommended,
    onClick,
}) => {
    let className = 'package-element';
    if (recommended) {
        className += ' package-element--recommended';
    }
    if (chosen) {
        className += ' package-element--chosen';
    }
    return (
        <div
            className={className}
            onClick={onClick}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
        >
            {title && <p className="package-element__title">{title}</p>}

            {recommended && (
                <p className="package-element__iconizing">
                    {WordingConstant.PackageScreen.iconizing}
                </p>
            )}
            <label htmlFor="idPrice" className="package-element__price">
                {price}
                <p>{Wording.priceUnit}</p>
            </label>
            <span>{Wording.priceCyclic}</span>
            {description && (
                <div className="package-element__description">
                    {description}
                </div>
            )}
            {chosen && <i className="checkmark" />}
        </div>
    );
};

export default PackageElement;
