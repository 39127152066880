import IconProfessional from '../assets/icons/ic-professional.svg';
import IconIndividual from '../assets/icons/ic-individual.svg';
import IconElecOff from '../assets/icons/ic-elec-off.svg';
import IconGasOff from '../assets/icons/ic-gas-off.svg';
import IconElecOn from '../assets/icons/ic-elec-on.svg';
import IconGasOn from '../assets/icons/ic-gas-on.svg';
import IconElecGasOff from '../assets/icons/ic-elec-gas-off.svg';
import IconElecGasOn from '../assets/icons/ic-elec-gas-on.svg';
import IconApartment from '../assets/icons/ic-apartment.svg';
import IconSecondary from '../assets/icons/ic-secondary.svg';
import IconCollectivity from '../assets/icons/ic-collectivity.svg';
import IconBill from '../assets/icons/ic-bill.svg';
import IconEnvelop from '../assets/icons/ic-envelop.svg';
import IconLogo from '../assets/icons/ic-logo-uppli.svg';
import IconElecGreen from '../assets/icons/ic-elec-green.svg';
import IconLinkedin from '../assets/icons/ic-linkedin.svg';
import IconInstagram from '../assets/icons/ic-instagram.svg';
import IconFacebook from '../assets/icons/ic-facebook.svg';
import IconYoutube from '../assets/icons/ic-youtube.svg';
import IconTwitter from '../assets/icons/ic-twitter.svg';
import IconCertification from '../assets/icons/ic-certification.svg';
import IconAvatar from '../assets/icons/ic-avatar.svg';

export default {
    'ic-elec-green': IconElecGreen,
    'ic-logo': IconLogo,
    'ic-professional': IconProfessional,
    'ic-individual': IconIndividual,
    'ic-elec-off': IconElecOff,
    'ic-gas-off': IconGasOff,
    'ic-elec-on': IconElecOn,
    'ic-gas-on': IconGasOn,
    'ic-elec-gas-on': IconElecGasOn,
    'ic-elec-gas-off': IconElecGasOff,
    'ic-apartment': IconApartment,
    'ic-secondary': IconSecondary,
    'ic-collectivity': IconCollectivity,
    'ic-bill': IconBill,
    'ic-envelop': IconEnvelop,
    'ic-linkedin': IconLinkedin,
    'ic-instagram': IconInstagram,
    'ic-facebook': IconFacebook,
    'ic-youtube': IconYoutube,
    'ic-twitter': IconTwitter,
    'ic-certification': IconCertification,
    'ic-avatar': IconAvatar,
};
