import React from 'react';

const EnergyDetail = ({ details }) => (
    <div className="energy-detail">
        {details &&
            details.map(({ title, value }, index) => {
                if (value)
                    return (
                        <span key={index}>
                            <p>{title}</p>
                            <p>{value}</p>
                        </span>
                    );
                return null;
            })}
    </div>
);

export default EnergyDetail;
