import { combineEpics, ofType } from 'redux-observable';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';

import {
    PREVIOUS_SCREEN_LOAD,
    NEXT_SCREEN_LOAD,
    MAIN_SCREEN_SAVE_ORDER,
    MAIN_SCREEN_SAVE_ORDER_SUCCESS,
    MAIN_SCREEN_SAVE_ORDER_FAILURE,
    MAIN_SCREEN_GET_ORDER,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    MAIN_SCREEN_GET_ORDER_FAILURE,
    MAIN_SCREEN_GET_OFFER_FAILURE,
    MAIN_SCREEN_GET_PACKAGE_FAILURE,
    MAIN_SCREEN_GET_PRODUCT_FAILURE,
} from '../types';

import { dateDiffDays } from '../../utils/helpers';
import { prepareOrder } from '../FinalSummaryScreen/FinalSummaryScreen.actions';

const goPreviousScreen = payload => ({
    type: PREVIOUS_SCREEN_LOAD,
    payload,
});

const goNextScreen = payload => ({
    type: NEXT_SCREEN_LOAD,
    payload,
});

const saveOrder = payload => ({
    type: MAIN_SCREEN_SAVE_ORDER,
    payload,
});

const saveOrderSuccess = payload => ({
    type: MAIN_SCREEN_SAVE_ORDER_SUCCESS,
    payload,
});

const saveOrderFailure = payload => ({
    type: MAIN_SCREEN_SAVE_ORDER_FAILURE,
    payload,
});

const getOrder = payload => ({
    type: MAIN_SCREEN_GET_ORDER,
    payload,
});

const getOrderSuccess = payload => ({
    type: MAIN_SCREEN_GET_ORDER_SUCCESS,
    payload,
});

const getOrderFailure = payload => ({
    type: MAIN_SCREEN_GET_ORDER_FAILURE,
    payload,
});

const getOfferFailure = payload => ({
    type: MAIN_SCREEN_GET_OFFER_FAILURE,
    payload,
});

const getPackageFailure = payload => ({
    type: MAIN_SCREEN_GET_PACKAGE_FAILURE,
    payload,
});

const getProductFailure = payload => ({
    type: MAIN_SCREEN_GET_PRODUCT_FAILURE,
    payload,
});

const preparePayload = payload => {
    const order = prepareOrder(payload);
    return {
        savingMode: 'SEND_EMAIL_LINK',
        order,
    };
};

const mapSaveOrder = (action, { apiRequest }) => {
    const payload = preparePayload(action.payload);
    return apiRequest({
        path: '/saveOrder',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.orderNumber) {
                return of(saveOrderSuccess(action.payload));
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        catchError(error =>
            of(
                saveOrderFailure({
                    error: error.message,
                })
            )
        )
    );
};

const saveOrderEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(MAIN_SCREEN_SAVE_ORDER),
        mergeMap(action => mapSaveOrder(action, dependency))
    );

const preparePayloadGetOrder = ({ id }) => ({
    orderNumber: id,
});

const mapGetOrder = (action, { apiRequest }) => {
    const payload = preparePayloadGetOrder(action.payload);
    return apiRequest({
        path: '/getOrder',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.order) {
                return of(response);
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        catchError(error =>
            of(
                getOrderFailure({
                    error: error.message,
                })
            )
        )
    );
};

const preparePayloadGetPackages = ({ userType, contracts }) => {
    const estimates = [];
    // flat() does not work in some browsers
    contracts.forEach(contract => {
        contract.estimates.forEach(est => {
            estimates.push({
                ...est,
                energy: contract.energy,
            });
        });
    });
    return {
        mode: 'ESTIMATE',
        customerType: userType,
        estimates,
    };
};

const mapGetPackages = (action, { apiRequest }) => {
    const payload = preparePayloadGetPackages(action.payload);
    return apiRequest({
        path: '/getPackages',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.packagesList) {
                return of(response);
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        catchError(error =>
            of(
                getPackageFailure({
                    error: error.message,
                })
            )
        )
    );
};

const preparePayloadGetProduct = ({ userType, contracts }) => {
    const modifiedContracts = contracts.map(contract => {
        const {
            effectiveStartRange,
            effectiveStartDate,
            subscriptionDate,
            ...c
        } = contract;
        return c;
    });
    return {
        contextOfUse: 'MARKET',
        customerType: userType,
        contracts: modifiedContracts,
    };
};

const preparePayloadGetOffer = ({ contracts, userType }) => {
    const updateArr = contracts.map(contract =>
        contract.chosenPackages === undefined
            ? { ...contract, chosenPackages: [] }
            : contract
    );

    return {
        contracts: updateArr,
        customerType: userType,
    };
};

const mapGetProducts = (action, { apiRequest }) => {
    const payload = preparePayloadGetProduct(action.payload);
    return apiRequest({
        path: '/getProducts',
        method: 'post',
        body: payload,
    }).pipe(
        switchMap(response => {
            if (response.productsList) {
                return of({
                    products: response.productsList,
                    error: null,
                });
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        switchMap(({ products }) => {
            const payloadOffer = preparePayloadGetOffer(action.payload);
            return apiRequest({
                path: '/getOffers',
                method: 'post',
                body: payloadOffer,
            }).pipe(
                switchMap(response => {
                    if (response.offers) {
                        return of({
                            products,
                            offers: response.offers,
                        });
                    }
                    const message = response.message || response.errorMessage;
                    return throwError({ message });
                }),
                catchError(error =>
                    of(
                        getOfferFailure({
                            error: error.message,
                        })
                    )
                )
            );
        }),
        catchError(error =>
            of(
                getProductFailure({
                    error: error.message,
                })
            )
        )
    );
};

const getOrderEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(MAIN_SCREEN_GET_ORDER),
        switchMap(
            action => mapGetOrder(action, dependency),
            (action, r) => [r]
        ),
        switchMap(([orderReponse]) => {
            const { order } = orderReponse;
            const diff = dateDiffDays(order.contracts[0].subscriptionDate);
            // expired link if subscriptionDate > 4 days

            const status =
                order.orderStatus === 'FINALIZED' ||
                order.orderStatus === 'FINALIZED_WITHOUT_DOC';
            if (diff > 0 || status) {
                const code = status ? 1 : 2;
                return throwError({
                    code,
                    message: 'Error get order',
                });
            }
            const getAction = {
                payload: {
                    userType: order.customer.type,
                    contracts: order.contracts,
                },
            };
            return forkJoin(
                of(order),
                mapGetPackages(getAction, dependency),
                mapGetProducts(getAction, dependency)
            );
        }),
        switchMap(([order, packageResponse, productResponse]) => {
            if (packageResponse.error && productResponse.error) {
                return of(
                    getOrderFailure({
                        error: 'Serveur répond avec erreur',
                    })
                );
            }
            const payload = {
                order,
                products: productResponse.products || [],
                offers: productResponse.offers || [],
                packages: packageResponse.packagesList,
                prepaymentFrequencies: packageResponse.prepaymentFrequencies,
                autorizedBillingModes: packageResponse.autorizedBillingModes,
            };
            return of(getOrderSuccess(payload));
        }),
        catchError(error =>
            of(
                getOrderFailure({
                    error: error.message,
                    code: error.code,
                })
            )
        )
    );

const mainScreenEpic = combineEpics(saveOrderEpic, getOrderEpic);

export { goPreviousScreen, goNextScreen, mainScreenEpic, saveOrder, getOrder };
