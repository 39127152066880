import React from 'react';

import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { CustomSelect } from '../../components';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.DataScreen;

const modifiersStyles = {
    highlighted: {
        color: 'white',
        backgroundColor: '#ffb40a',
    },

    outside: { color: '#8954ba', backgroundColor: 'white' },
};

class DateContainer extends React.Component {
    constructor(props) {
        super(props);
        const { dateContract } = props;
        this.state = {
            setting: {
                EL: {
                    hideDiv: false,
                    showSelect:
                        dateContract.EL && dateContract.EL.timeslot !== '',
                },
                NG: {
                    hideDiv: false,
                    showSelect:
                        dateContract.NG && dateContract.NG.timeslot !== '',
                },
            },
        };
    }

    renderInfosDiv = energy => (
        <div
            className={`date-container__date__calendars__${energy}-calendar__infos`}
        >
            <div
                className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__options`}
            >
                <div
                    className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__circle_1`}
                />
                <p>{Wording.contractDate.selectedDate}</p>
            </div>
            <div
                className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__options`}
            >
                <div
                    className={`date-container__date__info date-container__date__calendars__${energy}-calendar__infos__circle_2`}
                />
                <p> {Wording.contractDate.dateExtraCoat} </p>
            </div>
        </div>
    );

    handleChangeBool = (energy, type) => {
        const { setting } = this.state;
        if (type === 'open') setting[energy].hideDiv = true;
        else setting[energy].hideDiv = false;
        this.setState({ setting });
    };

    handleChangeBoolSelect = energy => {
        const { setting } = this.state;
        setting[energy].showSelect = true;
        this.setState({ setting });
    };

    renderCalendar = () => {
        const {
            energyTypes,
            dateContract,
            handleDatePicker,
            handleDateCalendarOption,
        } = this.props;
        const { setting } = this.state;
        return (
            <div className="date-container__date">
                {energyTypes &&
                    energyTypes.map((energy, index) => {
                        const currentDate = dateContract[energy].date
                            ? new Date(
                                  dateContract[energy].year,
                                  dateContract[energy].month,
                                  dateContract[energy].date
                              )
                            : undefined;
                        return (
                            <div
                                key={index}
                                className="date-container__date__calendars"
                            >
                                <div
                                    className={`date-container__date__calendars__${energy}-calendar`}
                                >
                                    <p>{Wording.contractDate[energy]}</p>
                                    <DayPickerInput
                                        placeholder="JJ/MM/AAAA"
                                        format="DD/MM/YYYY"
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        dayPickerProps={{
                                            className: 'selectable',
                                            selectedDays: handleDateCalendarOption(
                                                energy,
                                                'selected'
                                            ),
                                            disabledDays: handleDateCalendarOption(
                                                energy,
                                                'disabled'
                                            ),
                                            locale: Wording.contractDate.LOCAL,
                                            months:
                                                Wording.contractDate.MONTH[
                                                    Wording.contractDate.LOCAL
                                                ],
                                            weekdaysShort:
                                                Wording.contractDate
                                                    .WEEKDAYS_SHORT[
                                                    Wording.contractDate.LOCAL
                                                ],
                                            weekdaysLong:
                                                Wording.contractDate
                                                    .WEEKDAYS_LONG[
                                                    Wording.contractDate.LOCAL
                                                ],
                                            firstDayOfWeek:
                                                Wording.contractDate
                                                    .FIRST_DAY_OF_WEEK[
                                                    Wording.contractDate.LOCAL
                                                ],
                                            modifiers: {
                                                highlighted: currentDate,
                                                weekend: { daysOfWeek: [0, 6] },
                                            },
                                            modifiersStyles,
                                            month: new Date(
                                                new Date().getFullYear(),
                                                new Date().getMonth()
                                            ),
                                        }}
                                        keepFocus
                                        inputProps={{
                                            onFocus: () => {
                                                this.handleChangeBool(
                                                    energy,
                                                    'open'
                                                );
                                                this.handleChangeBoolSelect(
                                                    energy
                                                );
                                            },
                                        }}
                                        onDayChange={d => {
                                            handleDatePicker(d, energy);
                                        }}
                                        hideOnDayClick={false}
                                        onDayPickerHide={() =>
                                            this.handleChangeBool(
                                                energy,
                                                'close'
                                            )
                                        }
                                        value={currentDate}
                                    />

                                    {setting[energy].hideDiv &&
                                        this.renderInfosDiv(energy)}

                                    {setting[energy].showSelect &&
                                        this.renderSelectTimeslot(energy)}
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    renderSelectTimeslot = energy => {
        const { handleChangeTimeslotSelect, dateContract } = this.props;
        const ts = dateContract[energy].timeslots;
        if (!ts || ts.length === 0) {
            return null;
        }
        if (ts.indexOf('NONE') !== -1) {
            return (
                <p
                    className={`date-container__date__calendars__${energy}-calendar__paragraph`}
                >
                    {Wording.contractDate.noTimeslotLabel}
                </p>
            );
        }
        const timeslotOptions = Wording.contractDate.timeslots.values.filter(
            element => ts.find(val => val === element.value) !== undefined
        );

        const searchValue = Wording.contractDate.timeslots.values.find(
            e => e.value === dateContract[energy].timeslot
        );

        const value = searchValue
            ? searchValue.value
            : Wording.contractDate.placeholderSelector;

        return (
            <React.Fragment>
                <p
                    className={`date-container__date__calendars__${energy}-calendar__paragraph`}
                >
                    {Wording.contractDate.timeslotSelectorTitle}
                </p>
                <CustomSelect
                    id="idCreceau"
                    options={timeslotOptions}
                    value={value}
                    placeholder={Wording.contractDate.placeholderSelector}
                    handleChange={e => handleChangeTimeslotSelect(e, energy)}
                />
            </React.Fragment>
        );
    };

    render() {
        const { situation, dateContract, handleDropdownContract } = this.props;
        return (
            <div className="date-container">
                {situation !== '' && (
                    <p className="date-container__text">
                        {Wording.contractDate.title}
                    </p>
                )}

                {situation === Wording.situation.values.moved.value &&
                    this.renderCalendar()}
                {situation ===
                    Wording.situation.values.changeSupplier.value && (
                    <CustomSelect
                        id="idChangeSupplierCustomSelect"
                        options={Wording.contractDate.dropdownValues}
                        handleChange={handleDropdownContract}
                        value={
                            dateContract.switch
                                ? dateContract.switch.value
                                : null
                        }
                        placeholder={Wording.contractDate.placeholderSelector}
                    />
                )}
            </div>
        );
    }
}

export default DateContainer;
