import React from 'react';
import { connect } from 'react-redux';

import SearchService from '../../utils/search';

import SurveyContainer from '../../containers/SurveyContainer';
import * as actions from './SurveyScreen.actions';
import Screen from '../screen';
import { areListEqual } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.SurveyScreen;

export class SurveyScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.PACKAGE,
        previousScreen: Screen.CODE,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.SURVEY,
        showSaveButton: false,
        step: 1,
        validate: () => {},
        disabled: () => {},
    };

    constructor(props) {
        super(props);

        const { surveyState } = props;
        this.state = {
            fields: surveyState,
            setting: {
                postalCodes: [],
                codeText: '',
                showError: false,
            },
        };

        this.searchService = new SearchService();
    }

    componentDidMount() {
        this.searchService.getResults().subscribe(res => {
            const { setting } = this.state;
            if (res.length > 0) {
                setting.postalCodes = res.map(dt => ({
                    id: dt.code,
                    label: `${dt.code} - - - ${dt.city}`,
                    value: `${dt.code}__${dt.city}`,
                    codeValue: dt.code,
                    cityValue: dt.city,
                    netAreaValue: dt.netArea,
                }));
            } else {
                setting.postalCodes = [];
            }
            setting.isLoadingPC = false;
            this.setState({ setting });
        });
        SurveyScreen.navigationOptions.validate = () => {
            const { userState, validateSurveyScreenDispatch } = this.props;
            const { fields } = this.state;
            validateSurveyScreenDispatch({
                ...fields,
                ...userState,
            });
        };

        SurveyScreen.navigationOptions.disabled = () => {
            const { setting } = this.state;
            setting.showError = true;
            this.setState({ setting });
        };

        const { surveyState } = this.props;
        this.handleCondition(surveyState);
    }

    componentDidUpdate() {
        const { fields } = this.state;
        this.handleCondition(fields);
    }

    componentWillUnmount() {
        this.searchService.unsubscribe();
    }

    handleCondition = ({
        postalCode,
        city,
        housingType,
        residenceType,
        heatingType,
        priceOption,
    }) => {
        let condition =
            postalCode !== '' &&
            city !== '' &&
            housingType !== '' &&
            residenceType !== '' &&
            heatingType !== '';
        const {
            handleGoNext,
            userState: { energyTypes },
        } = this.props;
        if (!areListEqual(energyTypes, ['NG'])) {
            condition = condition && priceOption !== '';
        }

        if (handleGoNext) {
            if (condition) handleGoNext(true);
            else handleGoNext(false);
        }

        return condition;
    };

    handleInputChangeAddressContainer = value => {
        const { setting } = this.state;
        setting.codeText = value.replace(/[^\d ]/g, '');
        setting.isLoadingPC = true;
        const val = value.length > 5 ? value.substring(0, 4) : value;
        this.searchService.search(val);
        this.setState({ setting });
    };

    onFocusDropdown = () => {
        const { setting, fields } = this.state;
        if (fields.postalCode !== undefined) {
            setting.codeText = fields.postalCode.toString();
            this.setState({ setting });
        }
    };

    handleChangeDropdownSelect = object => {
        const { fields, setting } = this.state;
        const { codeValue, netAreaValue, cityValue } = object;
        fields.city = cityValue;
        fields.postalCode = codeValue;
        fields.netArea = netAreaValue;
        if (codeValue !== undefined) setting.codeText = codeValue.toString();
        setting.isLoadingPC = false;
        setting.postalCodes = [];
        this.setState({ fields, setting });
    };

    handleInputCity = e => {
        const { fields } = this.state;
        const { value } = e.target;
        fields.city = value;
        this.setState({ fields });
    };

    handleToggle = (value, type) => {
        const { fields } = this.state;
        if (fields[type] !== value) {
            fields[type] = value;
            this.setState({ fields });
        }
    };

    handleSelect = (selectedOption, type) => {
        const { fields } = this.state;
        if (fields[type] !== selectedOption.value) {
            fields[type] = selectedOption.value;
            this.setState({ fields });
        }
    };

    render() {
        const { fields, setting } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        const isNG = areListEqual(energyTypes, ['NG']);
        return (
            <div className="survey-screen">
                <SurveyContainer
                    setting={setting}
                    fields={fields}
                    condition={this.handleCondition}
                    handleInputCity={this.handleInputCity}
                    handleToggle={this.handleToggle}
                    handleSelect={this.handleSelect}
                    handleChangeDropdownSelect={this.handleChangeDropdownSelect}
                    handleInputChangeAddressContainer={
                        this.handleInputChangeAddressContainer
                    }
                    onFocusDropdown={this.onFocusDropdown}
                    showPriceOption={!isNG}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    surveyState: state.surveyReducer,
});

const mapDispatchToProps = dispatch => ({
    validateSurveyScreenDispatch: payload =>
        dispatch(actions.validateSurveyScreen(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SurveyScreen);
