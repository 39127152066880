import React from 'react';
import {
    CustomInput,
    CustomRadio,
    CustomToggle,
    CustomSelect,
    CustomDate,
} from '../../components';
import AddressContainer from '../AddressContainer';
import DateContainer from '../DateContainer';
import WordingConstant from '../../utils/wording.json';

import {
    handleConditionEmail,
    handleConditionPhone,
} from '../../utils/helpers';

const Wording = WordingConstant.DataScreen;
const UserScreenWording = WordingConstant.UserScreen;

const DataContainer = ({
    userState: { userType, energyTypes },
    codeState: { PDL, PCE },
    fields: {
        common: {
            situation,
            civility,
            name,
            surname,
            birthdate,
            email,
            tel,
            address,
            newAddress,
            newBillingAddress,
            dateContract,
        },
        part: { coOwner },
        pro: { socialReason, legalForm, APECode, SIRET },
    },
    setting: {
        postalCodes,
        isLoadingPC,
        codeText,
        codeNewAddressText,
        codeNewBillingAddressText,
        checkedNewBillingAddress,
        checkedNewAddress,
        checkedAddCoOwner,
        showError,
        showBottomText,
        validSiret,
    },
    handleChangeInput,
    handleToggle,
    handleToggleSituation,
    handleChangeSelect,
    handleDisplayValue,
    handleDatePicker,
    handleDropdownContract,
    handleDateCalendarOption,
    handleInputChangeAddressContainer,
    handleChangeDropdownSelect,
    handleChangeTimeslotSelect,
    onFocusDropdown,
    handleDateChange,
    condition,
}) => {
    const renderContractDate = () => (
        <DateContainer
            energyTypes={energyTypes}
            situation={situation}
            dateContract={dateContract}
            handleChangeTimeslotSelect={handleChangeTimeslotSelect}
            handleDateCalendarOption={handleDateCalendarOption}
            handleDatePicker={handleDatePicker}
            handleDropdownContract={handleDropdownContract}
        />
    );

    const renderEditAddress = () => (
        <div className="data-container__newAddress">
            <AddressContainer
                showError={showError}
                addressValue={newAddress.address}
                postalCodeValue={{ label: newAddress.postalCode }}
                cityValue={newAddress.city}
                handleInputAddress={e =>
                    handleChangeInput(e, 'newAddress', 'address')
                }
                handleInputPostalCode={value =>
                    handleInputChangeAddressContainer(
                        value,
                        'codeNewAddressText'
                    )
                }
                handleChangeDropdownSelect={obj =>
                    handleChangeDropdownSelect(obj, 'newAddress')
                }
                placeholder={newAddress.postalCode}
                options={postalCodes}
                isLoadingPC={isLoadingPC}
                inputValue={codeNewAddressText}
                onFocusDropdown={e => onFocusDropdown(e, 'newAddress')}
            />
            <span className="data-container__line" />
        </div>
    );

    const renderNewBillingAddressInput = () => (
        <div className="data-container__billingAddress">
            <div className="data-container__billingAddress__familySituation">
                <CustomSelect
                    id="civilityBilling"
                    text={Wording.civility.title}
                    value={newBillingAddress.civility}
                    defaultValue={newBillingAddress.civility}
                    options={Wording.civility.values}
                    handleChange={e =>
                        handleChangeSelect(
                            e,
                            'common',
                            'newBillingAddress',
                            'civility'
                        )
                    }
                />
            </div>
            <CustomInput
                text={Wording.coOwner.lastName}
                value={newBillingAddress.surname}
                onInputChange={e =>
                    handleChangeInput(e, 'newBillingAddress', 'surname')
                }
                error={
                    (newBillingAddress.surname === '' ||
                        newBillingAddress.surname === null) &&
                    showError &&
                    checkedNewBillingAddress
                        ? Wording.error.mainText
                        : null
                }
            />
            <CustomInput
                text={Wording.coOwner.firstName}
                value={newBillingAddress.name}
                onInputChange={e =>
                    handleChangeInput(e, 'newBillingAddress', 'name')
                }
                error={
                    (newBillingAddress.firstName === '' ||
                        newBillingAddress.surname === null) &&
                    showError &&
                    checkedNewBillingAddress
                        ? Wording.error.mainText
                        : null
                }
            />
            <AddressContainer
                showError={showError && checkedNewBillingAddress}
                addressValue={newBillingAddress.address}
                postalCodeValue={{
                    label:
                        codeNewBillingAddressText ||
                        newBillingAddress.postalCode,
                }}
                cityValue={newBillingAddress.city}
                handleInputAddress={e =>
                    handleChangeInput(e, 'newBillingAddress', 'address')
                }
                handleChangeDropdownSelect={obj =>
                    handleChangeDropdownSelect(obj, 'newBillingAddress')
                }
                handleInputPostalCode={value =>
                    handleInputChangeAddressContainer(
                        value,
                        'codeNewBillingAddressText'
                    )
                }
                placeholder={
                    codeNewBillingAddressText || newBillingAddress.postalCode
                }
                inputValue={codeNewBillingAddressText}
                options={postalCodes}
                isLoadingPC={isLoadingPC}
                onFocusDropdown={e => onFocusDropdown(e, 'newBillingAddress')}
            />
        </div>
    );

    const conditionSIRET = () => {
        if (SIRET.length < 14 && showError) return Wording.error.SIRET;
        if (validSiret !== 200 && SIRET.length === 14)
            return Wording.error.incorrectSIRET;
        return null;
    };

    const renderDataInputs = () => (
        <div className="data-container__inputs">
            <CustomInput
                id={Wording.lastName}
                text={Wording.lastName}
                value={surname}
                onInputChange={e => handleChangeInput(e, null, 'surname')}
                error={
                    surname === '' && showError ? Wording.error.mainText : null
                }
            />
            <CustomInput
                id={Wording.firstName}
                text={Wording.firstName}
                value={name}
                onInputChange={e => handleChangeInput(e, null, 'name')}
                error={name === '' && showError ? Wording.error.mainText : null}
            />

            <CustomDate
                value={birthdate === undefined ? '' : birthdate}
                text={Wording.birthdate}
                onDateChange={date => handleDateChange(date)}
                error={
                    (birthdate === '' || birthdate === undefined) && showError
                        ? Wording.error.mainText
                        : null
                }
            />
            {userType === UserScreenWording.userType.professional && (
                <React.Fragment>
                    <CustomInput
                        text={Wording.socialReason}
                        value={socialReason}
                        onInputChange={e =>
                            handleChangeInput(e, null, 'socialReason')
                        }
                        error={
                            socialReason === '' && showError
                                ? Wording.error.mainText
                                : null
                        }
                    />
                    <CustomSelect
                        id="idCompanyType"
                        text={Wording.legalForm.title}
                        defaultValue={legalForm}
                        options={Wording.legalForm.values}
                        handleChange={e =>
                            handleChangeSelect(e, 'pro', 'legalForm')
                        }
                        value={legalForm}
                        placeholder={Wording.legalForm.placeholder}
                        error={
                            legalForm === '' && showError
                                ? Wording.error.mainText
                                : null
                        }
                    />
                </React.Fragment>
            )}
            <AddressContainer
                showError={showError}
                addressValue={address.address}
                postalCodeValue={address.postalCode}
                cityValue={address.city}
                handleInputAddress={e =>
                    handleChangeInput(e, 'address', 'address')
                }
                handleInputPostalCode={value =>
                    handleInputChangeAddressContainer(value, 'codeText')
                }
                handleChangeDropdownSelect={obj =>
                    handleChangeDropdownSelect(obj, 'address')
                }
                placeholder={address.postalCode}
                options={postalCodes}
                isLoadingPC={isLoadingPC}
                isLocked={PDL !== '' || PCE !== ''}
                inputValue={codeText}
                onFocusDropdown={e => onFocusDropdown(e, 'address')}
            />
            <CustomInput
                text={Wording.email}
                value={email}
                id={Wording.email}
                type="email"
                onInputChange={e => handleChangeInput(e, null, 'email')}
                error={
                    showError && !handleConditionEmail(email)
                        ? Wording.error.email
                        : null
                }
            />
            <CustomInput
                text={Wording.tel}
                type="tel"
                value={handleDisplayValue(tel, 'tel')}
                minLength={10}
                maxLength={10}
                onInputChange={e => handleChangeInput(e, null, 'tel')}
                error={
                    showError && !handleConditionPhone(tel)
                        ? Wording.error.phone
                        : null
                }
            />
            {userType === UserScreenWording.userType.professional && (
                <React.Fragment>
                    <CustomInput
                        text={Wording.SIRET}
                        value={handleDisplayValue(SIRET, 'SIRET')}
                        onInputChange={e => handleChangeInput(e, null, 'SIRET')}
                        minLength={14}
                        maxLength={14}
                        error={conditionSIRET()}
                    />
                    <CustomInput
                        text={Wording.APECode}
                        value={APECode}
                        onInputChange={e =>
                            handleChangeInput(e, null, 'APECode')
                        }
                        isLocked
                    />
                </React.Fragment>
            )}
        </div>
    );

    const renderToggleInputs = () => (
        <div className="data-container__toggleInputs">
            {(PDL !== '' || PCE !== '') && (
                <React.Fragment>
                    <CustomToggle
                        id={Wording.toggleInputs.correctInfo.id}
                        text={Wording.toggleInputs.correctInfo.title}
                        checked={!checkedNewAddress}
                        handleCheck={e => handleToggle(e, 'checkedNewAddress')}
                    />
                    <CustomToggle
                        id={Wording.toggleInputs.changeInfo.id}
                        text={Wording.toggleInputs.changeInfo.title}
                        checked={checkedNewAddress}
                        handleCheck={e => handleToggle(e, 'checkedNewAddress')}
                    />
                </React.Fragment>
            )}
            {checkedNewAddress && renderEditAddress()}

            <CustomToggle
                id={Wording.toggleInputs.newBillingAddress.id}
                text={Wording.toggleInputs.newBillingAddress.title}
                checked={checkedNewBillingAddress}
                handleCheck={e => handleToggle(e, 'checkedNewBillingAddress')}
            />
            {checkedNewBillingAddress && renderNewBillingAddressInput()}
        </div>
    );

    return (
        <div className="data-container">
            <div className="data-container__familySituation">
                <CustomSelect
                    id="civility"
                    value={civility}
                    text={Wording.civility.title}
                    defaultValue={civility}
                    options={Wording.civility.values}
                    handleChange={e =>
                        handleChangeSelect(e, 'common', 'civility')
                    }
                />
            </div>
            {renderDataInputs()}
            <span className="data-container__line" />
            {renderToggleInputs()}
            <span className="data-container__line" />

            <div className="data-container__situationCheckbox">
                <label htmlFor="idSituationTitle">
                    {Wording.situation.title}
                </label>

                <div className="data-container__situationCheckbox__content">
                    <div className="data-container__situationCheckbox__content__radios">
                        <CustomRadio
                            id={Wording.situation.values.moved.id}
                            text={Wording.situation.values.moved.text}
                            checked={
                                situation ===
                                Wording.situation.values.moved.value
                            }
                            value={Wording.situation.values.moved.value}
                            handleCheck={e =>
                                handleToggleSituation(e, 'situation')
                            }
                        />
                        <CustomRadio
                            id={Wording.situation.values.changeSupplier.id}
                            text={Wording.situation.values.changeSupplier.text}
                            checked={
                                situation ===
                                Wording.situation.values.changeSupplier.value
                            }
                            value={
                                Wording.situation.values.changeSupplier.value
                            }
                            handleCheck={e =>
                                handleToggleSituation(e, 'situation')
                            }
                        />
                    </div>

                    {situation === '' && showError ? (
                        <p className="data-container__situationCheckbox__content__error">
                            {Wording.error.mainText}
                        </p>
                    ) : null}
                </div>
            </div>
            {renderContractDate()}

            {showBottomText && (
                <div className="data-container__paragraph">
                    <p>{Wording.footerText}</p>
                </div>
            )}

            <span className="data-container__line" />

            {userType !== UserScreenWording.userType.professional && (
                <React.Fragment>
                    <div
                        className="data-container__identity__link"
                        onClick={e => handleToggle(e, 'checkedAddCoOwner')}
                        role="button"
                        tabIndex="0"
                        onKeyPress={() => {}}
                    >
                        <CustomToggle
                            checked={checkedAddCoOwner}
                            text="Ajouter une personne co-titulaire"
                            handleCheck={() => {}}
                        />
                    </div>
                    {checkedAddCoOwner && (
                        <React.Fragment>
                            <div className="data-container__familySituation">
                                <CustomSelect
                                    text={Wording.civility.title}
                                    id="civilityCoOwner"
                                    defaultValue={coOwner.civility}
                                    value={coOwner.civility}
                                    options={Wording.civility.values}
                                    handleChange={e =>
                                        handleChangeSelect(
                                            e,
                                            'part',
                                            'coOwner',
                                            'civility'
                                        )
                                    }
                                />
                            </div>
                            <div className="data-container__inputs">
                                <CustomInput
                                    text={Wording.coOwner.lastName}
                                    value={coOwner.lastName}
                                    onInputChange={e =>
                                        handleChangeInput(
                                            e,
                                            'coOwner',
                                            'lastName'
                                        )
                                    }
                                    error={
                                        coOwner.lastName === '' && showError
                                            ? Wording.error.mainText
                                            : null
                                    }
                                />
                                <CustomInput
                                    text={Wording.coOwner.firstName}
                                    value={coOwner.firstName}
                                    onInputChange={e =>
                                        handleChangeInput(
                                            e,
                                            'coOwner',
                                            'firstName'
                                        )
                                    }
                                    error={
                                        coOwner.firstName === '' && showError
                                            ? Wording.error.mainText
                                            : null
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}

            {showError && !condition() && (
                <p className="data-container__error">
                    {Wording.error.bottomText}
                </p>
            )}
        </div>
    );
};
export default DataContainer;
