import React from 'react';
import { connect } from 'react-redux';

import SummaryContainer from '../../containers/SummaryContainer';
import Screen from '../screen';
import * as actions from './SummaryScreen.actions';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.SummaryScreen;

class SummaryScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.DATA,
        previousScreen: Screen.PACKAGE,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.SUMMARY,
        showSaveButton: false,
        step: 2,
        validate: () => {},
    };

    componentDidMount() {
        SummaryScreen.navigationOptions.validate = () => {
            const { validateSummaryScreenDispatch } = this.props;
            validateSummaryScreenDispatch({});
        };

        const { handleGoNext } = this.props;
        if (handleGoNext) {
            handleGoNext(true);
        }
    }

    /**
     * Function to handle toggle in multiple choice products
     * options save offer.product.productCode
     */
    handleToggle = ({ target: { value, checked } }, type) => {
        const {
            userState: { userType },
            summaryState,
            changeOfferDispatch,
            changeProductOptionDispatch,
        } = this.props;

        const offer = summaryState.offers.find(
            off =>
                off.energy === type &&
                (checked ? !off.product.default : off.product.default)
        );
        if (offer) {
            const options = checked ? [value] : [];
            changeOfferDispatch({
                offer,
                options,
                energyType: type,
            });
        } else {
            const productCode = checked
                ? value
                : (
                      summaryState.products.find(
                          p => p.energy === type && p.default
                      ) || {}
                  ).productCode || '';
            changeProductOptionDispatch({
                ...summaryState,
                userType,
                productCode,
                energyType: type,
                contracts: summaryState.contracts.filter(
                    p => p.energy === type
                ),
            });
        }
    };

    render() {
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;
        return (
            <div className="summary-screen">
                {energyTypes &&
                    energyTypes.map((energy, index) => {
                        const s = summaryState[energy];
                        const offer = s.offer;
                        const options = s.options;
                        const prices = s.chosenPackages
                            ? s.chosenPackages.map(p => (p || {}).amount)
                            : [];
                        const products = summaryState.products
                            ? summaryState.products.filter(
                                  product =>
                                      !product.default &&
                                      product.energy === energy
                              )
                            : [];
                        return (
                            <SummaryContainer
                                key={index}
                                prices={prices}
                                paymentFrequency={s.installmentFrequency}
                                offer={offer}
                                products={products}
                                currentOptions={options}
                                handleToggle={e => this.handleToggle(e, energy)}
                                loadingOption={s.loadingOption}
                                energy={energy}
                                showSeparator={index + 1 < energyTypes.length}
                            />
                        );
                    })}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    summaryState: state.summaryReducer,
});

const mapDispatchToProps = dispatch => ({
    validateSummaryScreenDispatch: payload =>
        dispatch(actions.validateSummaryScreenSuccess(payload)),
    changeProductOptionDispatch: payload =>
        dispatch(actions.changeProductOption(payload)),
    changeOfferDispatch: payload => dispatch(actions.changeOffer(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SummaryScreen);
