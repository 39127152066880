import React from 'react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../../assets/loader.json';

import FooterContainer from '../../containers/FooterContainer';
import NavigationController from '../NavigationController';
import Screen from '../screen';

import { Header, Footer } from '../../components';
import resources from '../../utils/resources';

import * as actions from './MainScreen.actions';
import { getSponsor } from '../SummaryScreen/SummaryScreen.actions';

export class MainScreen extends React.Component {
    myProps = {
        handleGoNext: bool => {
            this.setState({ canGoNext: bool });
        },
        handleLoadScreen: screenId => {
            const { nextScreenDispatch } = this.props;
            nextScreenDispatch({ screenId });
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            canGoNext: false,
            isData: false,
            isReturnUrl: false,
        };
    }

    componentDidMount() {
        const url =
            window.location !== window.parent.location
                ? document.referrer
                : document.location.href;

        const params = new URL(url).searchParams;
        const id = params.get('id');
        if (id) {
            const { getOrderDispatch } = this.props;
            getOrderDispatch({ id });
        } else {
            const test = params.get('returnUrl');
            if (test === 'true') {
                this.setState({ isReturnUrl: true });
            }
            const sponsorId = params.get('sponsor');
            if (sponsorId) {
                const { getSponsorDispatch } = this.props;
                getSponsorDispatch({ sponsorId });
            }
        }
        const channel = params.get('channel');
        if (channel) {
            localStorage.setItem('channel', channel);
        }
    }

    handleLoadScreen = (screenId, validate) => {
        const { canGoNext, isData } = this.state;
        if ((canGoNext && validate) || isData) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            validate();
        }
    };

    handleDisabledValidate = disabled => {
        if (disabled !== undefined) {
            disabled();
        }
    };

    handleReturnButton = returnButton => {
        if (returnButton !== undefined) {
            returnButton();
        }
    };

    handlePreviousScreen = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const { previousScreenDispatch } = this.props;
        previousScreenDispatch({});
    };

    handleSaveOrder = () => {
        const {
            dataState,
            summaryState,
            userState,
            paymentState,
            saveOrderDispatch,
        } = this.props;
        saveOrderDispatch({
            ...userState,
            ...dataState,
            ...summaryState,
            ...paymentState,
        });
    };

    render() {
        const {
            mainState: { loading, error, screenIds, isRecover },
        } = this.props;
        const { canGoNext, isReturnUrl } = this.state;
        const currentScreen = NavigationController({
            type: (screenIds.slice(-1) || [Screen.USER])[0],
            ...this.myProps,
        });
        const { title, screenId } = currentScreen.type.navigationOptions;
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
        };
        if (isReturnUrl) {
            return null;
        }

        return (
            <div className="main-screen">
                <Header />
                <div className="main">
                    {title && (
                        <section className="main__title">
                            <h1>{title}</h1>
                            <img
                                src={resources['ic-logo']}
                                alt="main__title__kiwi-logo"
                            />
                        </section>
                    )}

                    <div className="main__content">{currentScreen}</div>

                    {loading && (
                        <div className="main__loader">
                            <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                                isStopped={false}
                            />
                        </div>
                    )}

                    <FooterContainer
                        canComeBack={isRecover && screenId === Screen.DATA}
                        {...currentScreen.type.navigationOptions}
                        canGoNext={canGoNext}
                        handlePreviousScreen={this.handlePreviousScreen}
                        handleLoadScreen={this.handleLoadScreen}
                        handleDisabledValidate={this.handleDisabledValidate}
                        handleReturnButton={this.handleReturnButton}
                        showTimeline
                        error={error}
                        handleSave={this.handleSaveOrder}
                    />
                </div>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    mainState: state.mainReducer,
    userState: state.userReducer,
    dataState: state.dataReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
    paymentState: state.paymentReducer,
});

const mapDispatchToProps = dispatch => ({
    previousScreenDispatch: payload =>
        dispatch(actions.goPreviousScreen(payload)),
    nextScreenDispatch: payload => dispatch(actions.goNextScreen(payload)),
    saveOrderDispatch: payload => dispatch(actions.saveOrder(payload)),
    getOrderDispatch: payload => dispatch(actions.getOrder(payload)),
    getSponsorDispatch: payload => dispatch(getSponsor(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainScreen);
