import React from 'react';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Uppli;

const Header = () => (
    <div className="header">
        <a
            href={Wording.uppliLink}
            target="_blank"
            rel="noopener noreferrer"
            className="header__logo"
        >
            <img src={resources['ic-logo']} alt="ic-logo" />
        </a>
        <a
            href={Wording.clientSpaceLink}
            target="_blank"
            rel="noopener noreferrer"
            className="header__client"
        >
            <img src={resources['ic-avatar']} alt="ic-avatar" />
            {Wording.clientSpaceText}
        </a>
    </div>
);

export default Header;
