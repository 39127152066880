import React from 'react';

import { CustomToggle, CustomInput } from '../../components';

const SwitchContainer = ({
    handleShowInput,
    handleChangeInput,
    showInput,
    toggleTitle,
    showBorder,
    description,
    thirdParty: { value, message, error, loading },
}) => (
    <div className="switch-container">
        <div
            className={`switch-container__toggle ${
                showBorder ? 'switch-container__toggle--border' : ''
            }`}
            onClick={handleShowInput}
            role="button"
            tabIndex="0"
            onKeyPress={() => {}}
        >
            <CustomToggle
                checked={showInput}
                text={toggleTitle}
                handleCheck={() => {}}
            />
        </div>
        {showInput && (
            <CustomInput
                text=""
                value={value}
                loading={loading}
                onInputChange={e => handleChangeInput(e, value)}
            />
        )}
        {message && (
            <p
                className={`switch-container__message switch-container__message--${
                    error ? 'red' : 'green'
                }`}
            >
                {message}
            </p>
        )}
        {description && (
            <p className="switch-container__description">{description}</p>
        )}
    </div>
);

export default SwitchContainer;
