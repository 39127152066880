import React from 'react';

import {
    PackageElement,
    CustomRadio,
    CustomModal,
    CustomToggle,
} from '../../components';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PackageScreen;

class PackageContainer extends React.Component {
    state = {
        showingModalFrequency: false,
    };

    handleOpenModalFrequency = () => {
        const { showingModalFrequency } = this.state;
        this.setState({ showingModalFrequency: !showingModalFrequency });
    };

    renderModalFrequency() {
        const { showingModalFrequency } = this.state;
        return (
            <CustomModal
                show={showingModalFrequency}
                showCloseButton
                handleClick={this.handleOpenModalFrequency}
            >
                <div className="modal__content">
                    <div className="modal__title">
                        <div className="custom-input__layer">?</div>
                        <p>{Wording.popup.frequency.title}</p>
                    </div>
                    <p>{Wording.popup.frequency.description}</p>
                </div>
            </CustomModal>
        );
    }

    render() {
        const {
            packageTitle,
            packageList,
            onChangePaymentFrequency,
            onChoosePackage,
            onChangeCyclicBill,
            kind,
            paymentFrequency,
            packageID,
            cyclicBill,
            showSeparator,
            prepaymentFrequencies,
            cyclicBillingMode,
            scheduleBillingMode,
            showError,
        } = this.props;
        return (
            <div className="package-container">
                {this.renderModalFrequency()}
                <h3 className="package-container__title">{packageTitle}</h3>
                <div className="package-container__slider">
                    {packageList &&
                        packageList.map(
                            ({ amount, id, recommended }, index) => (
                                <PackageElement
                                    key={index}
                                    price={amount}
                                    chosen={packageID === id}
                                    recommended={recommended}
                                    onClick={() => onChoosePackage(id)}
                                />
                            )
                        )}
                </div>
                {scheduleBillingMode && (
                    <div className="package-container__frequency">
                        <h3 className="package-container__frequency__title">
                            {Wording.frequency.title}
                            <span
                                className="custom-input__layer"
                                onClick={this.handleOpenModalFrequency}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex="0"
                            >
                                ?
                            </span>
                        </h3>
                        {prepaymentFrequencies &&
                            prepaymentFrequencies.map(
                                ({ frequency, fee, currency }, index) => {
                                    const label = (
                                        Wording.frequency.values.find(
                                            v => v.value === frequency
                                        ) || {}
                                    ).text;
                                    return (
                                        <CustomRadio
                                            key={index}
                                            text={label}
                                            value={frequency}
                                            id={`idPrepayment${index}__${kind}`}
                                            description={`${fee}${currency}`}
                                            checked={
                                                paymentFrequency === frequency
                                            }
                                            handleCheck={
                                                onChangePaymentFrequency
                                            }
                                        />
                                    );
                                }
                            )}
                    </div>
                )}

                {cyclicBillingMode ? (
                    <div className="package-container__cyclicBill">
                        <div className="package-container__cyclicBill__toggle">
                            <h3>{Wording.option.title}</h3>
                            <CustomToggle
                                text={cyclicBillingMode.label}
                                id={`idCyclicBill__${kind}`}
                                checked={cyclicBill}
                                handleCheck={onChangeCyclicBill}
                            />
                        </div>
                        {Wording.option.descriptions.map((d, index) => (
                            <p key={index}>{d}</p>
                        ))}
                    </div>
                ) : (
                    <>
                        {showError && (
                            <>
                                {packageID === '' && (
                                    <div className="package-container__error">
                                        <span>!</span>
                                        <p>{Wording.error.missingPackage}</p>
                                    </div>
                                )}
                                {paymentFrequency === '' && (
                                    <div className="package-container__error">
                                        <span>!</span>

                                        <p>{Wording.error.missingFrequency}</p>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}

                {showSeparator && <hr />}
            </div>
        );
    }
}

export default PackageContainer;
