import React from 'react';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Common;

const CustomModal = ({ show, showCloseButton, handleClick, children }) => (
    <div
        role="button"
        tabIndex="0"
        className={`custom-modal${show ? ' custom-modal--show-modal' : ''}`}
        onClick={handleClick}
        onKeyDown={handleClick}
    >
        <div
            role="button"
            tabIndex="0"
            className="custom-modal__content"
            onClick={e => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
        >
            {showCloseButton && (
                <span
                    className="custom-modal__content__close-button"
                    onClick={handleClick}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex="0"
                >
                    {Wording.popup.closeButton}
                </span>
            )}
            {children}
        </div>
    </div>
);

export default CustomModal;
