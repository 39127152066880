import { combineEpics, ofType } from 'redux-observable';
import { of, throwError, forkJoin } from 'rxjs';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';

import {
    DATA_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    FETCH_CALENDAR_APPOINTMENT,
    FETCH_CALENDAR_APPOINTMENT_SUCCESS,
    FETCH_CALENDAR_APPOINTMENT_FAILURE,
    DATA_SCREEN_SAVE_COMMON,
} from '../types';

const dataScreenSaveCommon = payload => ({
    type: DATA_SCREEN_SAVE_COMMON,
    payload,
});

const validateDataScreenSuccess = payload => ({
    type: DATA_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validateDataScreenSuccessFinal = payload => ({
    type: DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    payload,
});

const fetchCalendarAppointment = payload => ({
    type: FETCH_CALENDAR_APPOINTMENT,
    payload,
});

const fetchCalendarAppointmentSuccess = payload => ({
    type: FETCH_CALENDAR_APPOINTMENT_SUCCESS,
    payload,
});

const fetchCalendarAppointmentFailure = payload => ({
    type: FETCH_CALENDAR_APPOINTMENT_FAILURE,
    payload,
});

const preparePayload = (payload, energy) => {
    const dates = new Date().toISOString().split('T');
    let fromDate;
    if (dates && dates.length > 1) {
        fromDate = dates[0];
    }
    return {
        fromDate,
        energyType: energy,
        customerType: payload.userType,
        processType: 'MOVE_IN',
        deliveryState: payload.contracts[0].deliveryPoint.state || '',
        meterType: payload.contracts[0].deliveryPoint.meterType || '',
        smartMeterStatus:
            payload.contracts[0].deliveryPoint.smartMeterStatus || '',
        deliveryStatus: payload.contracts[0].deliveryPoint.deliveryStatus || '',
    };
};

const mapGetCalendarAndAppointment = (action, energy, { apiRequest }) => {
    const payload = preparePayload(action.payload, energy);
    return apiRequest({
        path: '/getCalendarAppointment',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response) {
                return of(response);
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        catchError(error => of(error))
    );
};

const validateCalendarAppointementEpic = (action$, store, dependency) =>
    action$.pipe(
        ofType(FETCH_CALENDAR_APPOINTMENT),
        switchMap(action =>
            forkJoin(
                ...action.payload.energyTypes.map(energy =>
                    mapGetCalendarAndAppointment(action, energy, dependency)
                )
            )
        ),
        switchMap(calendarResponse => {
            const arrFlat = calendarResponse.flat();
            return of(fetchCalendarAppointmentSuccess(arrFlat));
        }),
        catchError(error =>
            of(
                fetchCalendarAppointmentFailure({
                    error: error.message,
                })
            )
        )
    );

const dataScreenEpic = combineEpics(validateCalendarAppointementEpic);

export {
    validateDataScreenSuccess,
    validateDataScreenSuccessFinal,
    dataScreenEpic,
    fetchCalendarAppointment,
    dataScreenSaveCommon,
};
