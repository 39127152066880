import React from 'react';
import { List } from 'react-content-loader';
import CustomToggle from '../CustomToggle';

import { transformLabel } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.SummaryScreen;
const PriceWording = WordingConstant.Price;
const SummaryWording = WordingConstant.SummaryScreen;
const CommonWording = WordingConstant.Common;

const PlanElement = ({
    prices,
    offer,
    products,
    currentOptions,
    currentAdditionalOptions,
    handleToggle,
    loadingOption,
    idToggle,
    options,
}) => {
    const details = offer.details || [];
    const totalAmount = details.find(
        d => d.category === SummaryWording.detail.category.totalAmount
    );
    const subscription =
        totalAmount &&
        totalAmount.unitPrices.find(
            p => p.label === PriceWording.subscription.label
        );
    const additionalRates = details.filter(
        d => d.category === SummaryWording.detail.category.additionalRate
    );
    const totalBudget = details.find(
        d => d.category === SummaryWording.detail.category.totalBudget
    );
    const discountRates = details.find(
        d => d.category === SummaryWording.detail.category.discountRate
    );

    const renderPrice = ({ label, price, unit }, index) => {
        const displayedUnit = CommonWording.unit[unit];
        const display = transformLabel(label);
        if (display === '') return null;
        return (
            <p key={index}>
                {display} :{' '}
                <span>
                    {price} {displayedUnit}
                </span>
            </p>
        );
    };

    return (
        <div
            className={`plan-element ${
                prices.length === 0 ? `plan-element--cyclicBill` : ''
            }`}
        >
            {prices.length !== 0 && (
                <div
                    className={`plan-element__prices ${
                        prices.length > 1 ? 'plan-element__prices--larger' : ''
                    }`}
                >
                    {prices &&
                        prices.map((p, index) => (
                            <div
                                key={index}
                                className={`plan-element__prices__label ${
                                    index === 0 && prices.length > 1
                                        ? 'plan-element__prices__label--border'
                                        : ''
                                }`}
                            >
                                {prices.length > 1 && (
                                    <span className="plan-element__prices__label__timeframe">
                                        {
                                            WordingConstant.Common.timeframe[
                                                index
                                            ]
                                        }
                                    </span>
                                )}
                                <label
                                    htmlFor="idPrice"
                                    className="plan-element__prices__label__priceDescription"
                                >
                                    {p}
                                    <p>{WordingConstant.Common.priceUnit}</p>
                                </label>
                                <span className="plan-element__prices__label__month">
                                    {WordingConstant.Common.priceCyclic}
                                </span>
                            </div>
                        ))}
                </div>
            )}

            <div
                className={`plan-element__description ${
                    prices.length === 0
                        ? `plan-element__description--cyclicBill`
                        : ''
                }`}
            >
                {offer && loadingOption ? (
                    <List />
                ) : (
                    <React.Fragment>
                        <div className="plan-element__description__subscription">
                            <p className="plan-element__description__title">
                                {Wording.detail.title.fix}
                            </p>
                            {subscription && renderPrice(subscription, 0)}
                            <div className="plan-element__description__separator" />
                        </div>
                        <p className="plan-element__description__title">
                            {Wording.detail.title.consumption}
                        </p>
                        <p className="plan-element__description__subtitle">
                            {Wording.detail.title.grossPrice}
                        </p>
                        {totalAmount &&
                            totalAmount.unitPrices &&
                            totalAmount.unitPrices
                                .filter(
                                    p =>
                                        p.label !==
                                        PriceWording.subscription.label
                                )
                                .map((e, index) => renderPrice(e, index))}
                        <br className="plan-element__description__margin" />
                        {additionalRates.map(
                            (rate, idx1) =>
                                rate &&
                                rate.unitPrices &&
                                rate.unitPrices.map((e, idx2) =>
                                    renderPrice(e, idx1 + idx2)
                                )
                        )}
                        {totalBudget &&
                            totalBudget.unitPrices &&
                            totalBudget.unitPrices.map((e, index) =>
                                renderPrice(e, index)
                            )}
                        <br className="plan-element__description__margin" />
                        <p className="plan-element__description__subtitle">
                            {Wording.detail.title.discountPrice}
                        </p>
                        {discountRates &&
                            discountRates.unitPrices &&
                            discountRates.unitPrices.map((e, index) =>
                                renderPrice(e, index)
                            )}
                        {options &&
                            currentAdditionalOptions &&
                            currentAdditionalOptions.length > 0 && (
                                <React.Fragment>
                                    <div className="plan-element__description__separator" />
                                    <p className="plan-element__description__title">
                                        {Wording.detail.title.option}
                                    </p>
                                    {options.map((e, index) => {
                                        if (
                                            currentAdditionalOptions &&
                                            currentAdditionalOptions.includes(
                                                e.label
                                            ) &&
                                            e.detail
                                        ) {
                                            return renderPrice(e.detail, index);
                                        }
                                        return null;
                                    })}
                                </React.Fragment>
                            )}
                        <div className="plan-element__description__separator" />
                        {offer.product && offer.product.description && (
                            <p>{offer.product.description}</p>
                        )}
                    </React.Fragment>
                )}
            </div>

            <div
                className={`plan-element__toggle ${
                    prices.length === 0
                        ? `plan-element__toggle--cyclicBill`
                        : ''
                }`}
            >
                <div className="plan-element__toggle__productOptions">
                    {products &&
                        products.map((product, index) => (
                            <CustomToggle
                                key={index}
                                id={`idFixPrice__${idToggle || ''}__${index}`}
                                text={SummaryWording.fixPrice}
                                value={product.productCode}
                                checked={
                                    currentOptions &&
                                    currentOptions.includes(product.productCode)
                                }
                                handleCheck={handleToggle}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default PlanElement;
