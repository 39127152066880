import React from 'react';

import resources from '../../utils/resources';

import Screen from '../screen';

import WordingData from '../../utils/wording.json';

const Wording = WordingData.FinalScreen;

class FinalScreen extends React.Component {
    static navigationOptions = {
        nextScreen: null,
        previousScreen: null,
        buttonNextTitle: '',
        buttonPreviousTitle: '',
        title: Wording.title,
        screenId: Screen.FINAL,
        showSaveButton: false,
        step: 4,
        validate: () => {},
    };

    componentDidMount() {
        window.history.replaceState(null, null, window.location.pathname);
    }

    render() {
        return (
            <div className="final-screen">
                <img
                    className="final-screen__img"
                    src={resources['ic-logo']}
                    alt={Wording.text1}
                />
                <h1 className="final-screen__text">{Wording.text1}</h1>
                <h1 className="final-screen__text">{Wording.text2}</h1>
            </div>
        );
    }
}

export default FinalScreen;
