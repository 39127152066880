import React from 'react';
import HorizontalTimeline from './HorizontalTimeline';

const Timeline = ({ items, step }) => {
    const config = {
        width: `${80}%`,
        height: `${40}px`,
        linePadding: 150,
        labelWidth: 130,
        fillingMotionStiffness: 150,
        fillingMotionDamping: 25,
        slidingMotionStiffness: 150,
        slidingMotionDamping: 25,
        styles: {
            foreground: '#ffffff',
            background: '#000000',
            outline: '#ffffff',
            // foreground: '#000000',
            // background: '#ffffff',
            // outline: '#000000',
        },
    };
    return (
        <HorizontalTimeline
            values={items}
            fillingMotion={{
                stiffness: config.fillingMotionStiffness,
                damping: config.fillingMotionDamping,
            }}
            index={step - 1}
            indexClick={() => {}}
            labelWidth={config.labelWidth}
            linePadding={config.linePadding}
            slidingMotion={{
                stiffness: config.slidingMotionStiffness,
                damping: config.slidingMotionDamping,
            }}
            styles={config.styles}
            containerWidth={config.width}
            containerHeight={config.height}
        />
    );
};

export default Timeline;
