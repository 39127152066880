import React from 'react';
import { connect } from 'react-redux';

import UserContainer from '../../containers/UserContainer';
import * as actions from './UserScreen.actions';

import Screen from '../screen';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.UserScreen;

class UserScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.CODE,
        previousScreen: null,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.USER,
        showSaveButton: false,
        step: 1,
        validate: () => {},
    };

    constructor(props) {
        super(props);
        const { userState } = props;
        this.state = {
            fields: userState,
        };
    }

    componentDidMount() {
        const { validateUserScreenDispatch } = this.props;
        const { fields } = this.state;
        UserScreen.navigationOptions.validate = () =>
            validateUserScreenDispatch(fields);

        const {
            handleGoNext,
            userState: { userType, energyTypes },
        } = this.props;
        if (handleGoNext) {
            if (userType !== '' && energyTypes.length > 0) handleGoNext(true);
            else handleGoNext(false);
        }
    }

    componentDidUpdate() {
        const { handleGoNext } = this.props;
        const {
            fields: { userType, energyTypes },
        } = this.state;
        if (handleGoNext) {
            if (userType !== '' && energyTypes.length > 0) handleGoNext(true);
            else handleGoNext(false);
        }
    }

    handleOnClick = (value, type) => {
        const { fields } = this.state;
        fields[type] = value;
        this.setState({ fields });
    };

    handleHideBanner = () => {
        const { resetRecoverDispatch } = this.props;
        resetRecoverDispatch();
    };

    render() {
        const { fields } = this.state;
        const {
            mainState: { errorRecovery },
        } = this.props;
        const { items } = Wording;
        return (
            <div
                className="user-screen"
                onClick={this.handleHideBanner}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
            >
                {errorRecovery && (
                    <div className="user-screen__invalidLink">
                        <div className="custom-input__layer">?</div>
                        <p>{errorRecovery}</p>
                        <span
                            className=""
                            onClick={this.handleHideBanner}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex="-1"
                        >
                            X
                        </span>
                    </div>
                )}

                {items &&
                    items.map(({ itemType, title, values }, index) => (
                        <UserContainer
                            key={index}
                            title={title}
                            choiceType={fields[itemType]}
                            imageList={values}
                            onClick={value =>
                                this.handleOnClick(value, itemType)
                            }
                        />
                    ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validateUserScreenDispatch: payload =>
        dispatch(actions.validateUserScreenSuccess(payload)),
    resetRecoverDispatch: payload => dispatch(actions.resetRecover(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserScreen);
