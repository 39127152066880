import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';

import Config from './config';
import { log } from './helpers';

const apiRequest = ({ token, path, method, body, isLocal }) => {
    const modifiedBody = {
        ...body,
        channel: 'WEB',
        seller: 'WEKIWI',
    };
    const channel = localStorage.getItem('channel');
    if (channel) {
        modifiedBody.channel = channel;
    }
    const settings = {
        url: `${isLocal ? Config.URL_LOCAL : Config.URL}${path}`,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': Config.ApiKey,
        },
        responseType: 'json',
        crossDomain: true,
        method,
        body: modifiedBody,
    };

    log(`FO Data: POST ${path} ====> `, modifiedBody);

    if (token) {
        settings.headers.Authorization = `Bearer: ${token}`;
    }

    return ajax(settings).pipe(
        catchError(({ message, response, status }) => {
            const error = {
                ajaxError: message,
                code: status,
                message: 'Service indisponible',
            };
            if (response) {
                error.code = response.code;
                error.message = response.message;
            }
            log(`Error from API ${path} ====> `, error);
            return throwError(error);
        }),
        map(({ response }) => {
            log(`BO Response: POST ${path} ====> `, response);
            return response;
        })
    );
};

export const searchAppointmentTimeslot = async (date, energy) => {
    let day = date.getDate();
    day = `${day < 10 ? `0${day}` : day}`;
    let month = date.getMonth();
    month = `${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
    const data = {
        date: `${date.getFullYear()}-${month}-${day}`,
        energy,
    };
    log(`FO Data: POST /getAppointmentTimeslot ====> `, data);
    try {
        const res = await fetch(`${Config.URL}/getAppointmentTimeslot`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': Config.ApiKey,
            },
            body: JSON.stringify(data),
        });
        const response = await res.json();
        log(`BO Response: POST /getAppointmentTimeslot ====> `, response);
        const ts = response.timeslots;
        if (ts) {
            return ts;
        }
        throw res.message || { message: 'Service indisponible' };
    } catch (error) {
        log(`Error from API /getAppointmentTimeslot ====> `, error);
        return [];
    }
};

export const searchSiret = async value => {
    try {
        log(`FO Data: GET /getSiret ====> `, value);
        const res = await fetch(`${Config.URL_GET_SIRET}/${value}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });
        if (res.status) {
            const response = await res.json();
            log(`BO Response: GET /getSiret ====> `, response);
            return {
                ...response,
                status: res.status,
            };
        }
        throw res.message || { message: 'Service indisponible' };
    } catch (error) {
        log(`Error from API /getSiret ====> `, error);
        return null;
    }
};

export default apiRequest;
