import React from 'react';

const CustomInput = ({
    isLocked,
    text,
    value,
    type,
    error,
    onInputChange,
    onToggleModal,
    minLength,
    maxLength,
    isInterrogationVisible,
    defaultValue,
    placeholder,
    loading,
}) => (
    <div className="custom-input">
        {text && (
            <label htmlFor={`id${text}`}>
                {text}
                {isInterrogationVisible && (
                    <span
                        className="custom-input__layer"
                        onClick={onToggleModal}
                        onKeyDown={onToggleModal}
                        role="button"
                        tabIndex="0"
                    >
                        ?
                    </span>
                )}
            </label>
        )}
        <div className="custom-input__div">
            <input
                className={`custom-input__div__input${
                    isLocked ? ' custom-input__div__input--locked' : ''
                } ${loading ? 'custom-input__div__input__loading' : ''}`}
                onChange={onInputChange}
                type={type}
                value={value || ''}
                minLength={minLength}
                maxLength={maxLength}
                defaultValue={defaultValue}
                placeholder={placeholder}
            />
            {error && error !== '' && (
                <p className="custom-input__div__error">{error}</p>
            )}
        </div>
    </div>
);

export default CustomInput;
