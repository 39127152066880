import React from 'react';
import { ImageElement } from '../../components';

import resources from '../../utils/resources';
import { areListEqual, sendImage } from '../../utils/helpers';

const UserContainer = ({ imageList, title, choiceType, onClick }) => (
    <div className="user-container">
        <h3>{title}</h3>
        <div className="user-container__images">
            {imageList &&
                imageList.map(
                    ({ text, value, imageSrc, imageSrcOn }, index) => (
                        <ImageElement
                            key={index}
                            isChecked={
                                typeof choiceType === 'string'
                                    ? choiceType === value
                                    : areListEqual(choiceType, value)
                            }
                            text={text}
                            imageSrc={
                                resources[
                                    sendImage(
                                        choiceType,
                                        value,
                                        imageSrcOn,
                                        imageSrc
                                    )
                                ]
                            }
                            onClick={() => onClick(value)}
                        />
                    )
                )}
        </div>
    </div>
);

export default UserContainer;
