import React from 'react';

import CustomToggle from '../CustomToggle';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.FinalSummaryScreen;

const OptionElement = ({
    options,
    currentAdditionalOptions,
    handleToggleAdditionalOptions,
    idToggle,
}) => (
    <div className="option-element">
        <h1> {Wording.options.title}</h1>
        <div className="option-element__content">
            {options &&
                options.map((option, index) => (
                    <div key={index} className="option-element__content__item">
                        <div className="option-element__content__item__toggle">
                            <CustomToggle
                                key={index}
                                id={`idOption__${idToggle || ''}__${index}`}
                                text={option.label}
                                value={option.label}
                                checked={
                                    currentAdditionalOptions &&
                                    currentAdditionalOptions.includes(
                                        option.label
                                    )
                                }
                                handleCheck={handleToggleAdditionalOptions}
                            />
                            <img
                                src={resources[Wording.options.imageSrc]}
                                alt="elec"
                            />
                        </div>
                        {option.description && (
                            <p className="option-element__content__item__description">
                                {option.description}
                            </p>
                        )}
                    </div>
                ))}
        </div>
    </div>
);

export default OptionElement;
