import React from 'react';
import { List } from 'react-content-loader';

import WordingConstant from '../../utils/wording.json';

const PackageWording = WordingConstant.PackageScreen;
const SummaryWording = WordingConstant.SummaryScreen;
const CommonWording = WordingConstant.Common;

const SummaryElement = ({ offer, paymentFrequency, loadingOption }) => {
    const frequency =
        PackageWording.frequency.values &&
        (
            PackageWording.frequency.values.find(
                v => v.value === paymentFrequency
            ) || {}
        ).text;
    const details = offer.details || [];
    const totalAmount = details.find(
        d => d.category === SummaryWording.detail.category.totalAmount
    );
    const additionalRates = details.filter(
        d => d.category === SummaryWording.detail.category.additionalRate
    );
    const totalBudget = details.find(
        d => d.category === SummaryWording.detail.category.totalBudget
    );
    const economy = details.find(
        d => d.category === SummaryWording.detail.category.economy
    );

    const renderPrice = ({ label, amount, unit }, index) => {
        const displayedUnit = CommonWording.unit[unit];
        return (
            <p
                key={index}
                className={`summary-element__value ${
                    index === 101 ? 'summary-element__value--bold' : ''
                }`}
            >
                {label} :{' '}
                <span>
                    {amount} {displayedUnit}
                </span>
            </p>
        );
    };

    return (
        <div className="summary-element">
            <h3>
                {paymentFrequency === ''
                    ? PackageWording.frequency.titleCyclic
                    : PackageWording.frequency.title}
            </h3>

            <p className="summary-element__value">{frequency}</p>

            {offer && loadingOption ? (
                <List />
            ) : (
                <React.Fragment>
                    <h3>{SummaryWording.detail.budget.title}</h3>
                    {totalAmount && renderPrice(totalAmount, 100)}
                    {additionalRates.map((d, index) => renderPrice(d, index))}
                    {totalBudget && renderPrice(totalBudget, 101)}
                    {economy && economy.amount > 0 && (
                        <React.Fragment>
                            <h3>
                                {SummaryWording.detail.budget.estimatedEconomy}
                            </h3>
                            {renderPrice(economy, 102)}
                        </React.Fragment>
                    )}
                    <p className="summary-element__description">
                        {SummaryWording.detail.budget.description}
                    </p>
                    {economy && economy.amount > 0 && (
                        <p className="summary-element__description">
                            {SummaryWording.detail.budget.notice}
                        </p>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default SummaryElement;
