import React from 'react';
import { connect } from 'react-redux';
import * as actions from './PaymentScreen.actions';
import Screen from '../screen';
import WordingData from '../../utils/wording.json';
import { log } from '../../utils/helpers';

const Wording = WordingData.PaymentScreen;

class PaymentScreen extends React.Component {
    static navigationOptions = {
        nextScreen: null,
        previousScreen: null,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.PAYMENT,
        showSaveButton: false,
        step: 4,
        validate: () => {},
    };

    handleIframe = () => {
        const { contentWindow } = this.iframeRef;
        if (contentWindow) {
            const { location } = contentWindow;
            try {
                if (location && location.host) {
                    const {
                        paymentState: { reference },
                        validatePaymentScreenDispatch,
                    } = this.props;
                    validatePaymentScreenDispatch({ reference });
                }
            } catch (error) {
                log('iframe error ', error.message);
            }
        }
    };

    render() {
        const {
            paymentState: { iframe },
            mainState: { loadingPaymentStatus },
        } = this.props;
        // Note: In order to customize the iframe css (for ex: remove default background), we take its src url
        // instead of iframe string generated by Slimpay.
        // const frame = `
        //         <iframe
        //             id="idIframe"
        //             src=${iframe}
        //             className="payment-screen__iframe"
        //             onLoad={${this.handleIframe()}}
        //         />
        // `;
        if (loadingPaymentStatus) {
            return <div className="payment-screen" />;
        }
        return (
            <div className="payment-screen">
                {iframe && (
                    <iframe
                        title="uniqueTitle"
                        ref={ref => {
                            this.iframeRef = ref;
                        }}
                        src={iframe}
                        className="payment-screen__iframe"
                        onLoad={this.handleIframe}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    paymentState: state.paymentReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validatePaymentScreenDispatch: payload =>
        dispatch(actions.validatePaymentScreen(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentScreen);
