import React from 'react';

import {
    PlanElement,
    EnergyDetail,
    EnergyGauge,
    CustomButton,
    CustomModal,
    PackageElement,
    OptionElement,
} from '../../components';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.FinalSummaryScreen;
const SummaryWording = WordingConstant.SummaryScreen;

class FinalSummaryContainer extends React.Component {
    constructor(props) {
        super(props);
        const { currentPackage } = props;
        this.state = {
            toggle: {
                setting: false,
                gauge: false,
                modal: false,
            },
            fields: {
                prices: [],
                packageID: currentPackage.id,
            },
        };
    }

    handleToggle = (type, val) => {
        const { toggle } = this.state;
        toggle[type] = val;
        this.setState({ toggle });
    };

    handleChangeFields = (name, value) => {
        const { fields } = this.state;
        fields[name] = value;
        this.setState({ fields });
    };

    renderModal() {
        const {
            toggle: { modal },
        } = this.state;
        return (
            <CustomModal
                show={modal}
                showCloseButton
                handleClick={() => this.handleToggle('modal', !modal)}
            >
                <div className="final-summary-container__modal__content">
                    <div className="final-summary-container__modal__title">
                        <div className="custom-input__layer">?</div>
                        <p>{Wording.popup.title}</p>
                    </div>
                    {Wording.popup.contents.map((content, index) => (
                        <p
                            key={index}
                            className="final-summary-container__modal__content__text"
                        >
                            {content}
                        </p>
                    ))}
                </div>
            </CustomModal>
        );
    }

    renderGauge(energy) {
        const {
            packages,
            ranges,
            handleChangeFields,
            handleResetFields,
        } = this.props;
        const {
            toggle: { setting, gauge, modal },
            fields: { packageID, prices },
        } = this.state;
        let initialProgress = 0;
        if (prices && prices.length > 1) {
            initialProgress = ranges.findIndex(
                r => r[0] === prices[0] && r[1] === prices[1]
            );
            initialProgress = initialProgress === -1 ? 0 : initialProgress;
        }
        const reducer = (accumulator, current) => {
            const max = current[0] > current[1] ? current[0] : current[1];
            return accumulator > max ? accumulator : max;
        };
        const gaugeMaxValue = ranges ? ranges.reduce(reducer, 0) : 0;
        if (!setting) {
            return (
                <div className="gauge-container">
                    <CustomButton
                        title={Wording.plan.gauge.buttonTitle}
                        color="orange"
                        onClick={() => this.handleToggle('setting', !setting)}
                    />
                    <div
                        className="gauge-container__instruction"
                        onClick={() => this.handleToggle('modal', !modal)}
                        tabIndex="0"
                        onKeyPress={() => {}}
                        role="button"
                    >
                        {Wording.plan.descriptionBenefit}
                    </div>
                </div>
            );
        }
        return (
            <div className="gauge-container">
                <p className="gauge-container__title">
                    {Wording.plan.gauge.title}
                </p>

                <div className="gauge-container__gauge">
                    <input
                        id={`tab1__${energy}`}
                        type="radio"
                        checked={!gauge}
                        onChange={() => this.handleToggle('gauge', false)}
                    />
                    <input
                        id={`tab2__${energy}`}
                        type="radio"
                        checked={gauge}
                        onChange={() => this.handleToggle('gauge', true)}
                    />

                    <ul>
                        <li className={`tab1__${energy}`}>
                            <label htmlFor={`tab1__${energy}`}>
                                {Wording.plan.gauge.tab1}
                            </label>
                        </li>
                        <li className={`tab2__${energy}`}>
                            <label htmlFor={`tab2__${energy}`}>
                                {Wording.plan.gauge.tab2}
                            </label>
                        </li>
                    </ul>

                    <section>
                        {!gauge ? (
                            <div className={`tab1__${energy}`}>
                                {packages &&
                                    packages.map(
                                        (
                                            { amount, id, recommended },
                                            index
                                        ) => (
                                            <PackageElement
                                                key={index}
                                                price={amount}
                                                chosen={id === packageID}
                                                recommended={recommended}
                                                onClick={() =>
                                                    this.handleChangeFields(
                                                        'packageID',
                                                        id
                                                    )
                                                }
                                            />
                                        )
                                    )}
                            </div>
                        ) : (
                            <div className={`tab2__${energy}`}>
                                <EnergyGauge
                                    initial={initialProgress}
                                    maxValue={gaugeMaxValue}
                                    ranges={ranges}
                                    onChange={p =>
                                        this.handleChangeFields('prices', p)
                                    }
                                />
                            </div>
                        )}
                    </section>
                </div>
                <div className="gauge-container__button">
                    <CustomButton
                        title={Wording.plan.gauge.cancelButton}
                        color="gray"
                        onClick={() => {
                            this.handleToggle('setting', !setting);
                            if (gauge) {
                                handleResetFields('prices');
                            } else {
                                handleResetFields('packageID');
                            }
                        }}
                    />
                    <CustomButton
                        title={Wording.plan.gauge.validateButton}
                        color="orange"
                        onClick={() => {
                            this.handleToggle('setting', !setting);
                            if (gauge) {
                                handleChangeFields(
                                    prices.length === 0 ? ranges[0] : prices,
                                    ''
                                );
                            } else {
                                handleChangeFields([], packageID);
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            prices,
            currentOptions,
            currentAdditionalOptions,
            handleToggle,
            handleToggleAdditionalOptions,
            offer,
            products,
            energy,
            details,
            loadingOption,
            options,
            showSeparator,
            cyclicBill,
        } = this.props;
        return (
            <div className="final-summary-container">
                {this.renderModal()}
                <h3>{SummaryWording.subtitle[energy]}</h3>
                <div className="final-summary-container__body">
                    <PlanElement
                        prices={prices}
                        offer={offer}
                        products={products}
                        handleToggle={handleToggle}
                        idToggle={energy}
                        currentOptions={currentOptions}
                        currentAdditionalOptions={currentAdditionalOptions}
                        options={options}
                        loadingOption={loadingOption}
                    />

                    <div className="final-summary-container__body__right">
                        <EnergyDetail details={details} />

                        {options && options.length !== 0 && (
                            <OptionElement
                                options={options}
                                idToggle={energy}
                                currentAdditionalOptions={
                                    currentAdditionalOptions
                                }
                                handleToggleAdditionalOptions={
                                    handleToggleAdditionalOptions
                                }
                            />
                        )}
                    </div>
                </div>
                {!cyclicBill && this.renderGauge(energy)}

                {showSeparator && <hr />}
            </div>
        );
    }
}

export default FinalSummaryContainer;
