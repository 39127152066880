import React from 'react';
import { connect } from 'react-redux';

import DataContainer from '../../containers/DataContainer';
import Screen from '../screen';
import SearchService from '../../utils/search';
import * as actions from './DataScreen.actions';
import * as helpers from '../../utils/helpers';
import { searchSiret, searchAppointmentTimeslot } from '../../utils/apiRequest';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.DataScreen;
const UserScreenWording = WordingConstant.UserScreen;

export class DataScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.FINALSUMMARY,
        previousScreen: Screen.SUMMARY,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.DATA,
        showSaveButton: false,
        step: 3,
        validate: () => {},
        disabled: () => {},
        returnButton: () => {},
    };

    constructor(props) {
        super(props);
        const {
            dataState,
            summaryState: { contracts },
        } = props;
        const { address } = contracts[0].deliveryPoint;
        let currentAddress;
        const { common, part } = dataState;
        if (address && address.address) {
            let fullAddress = `${address.street || ''}`;
            if (address.number) {
                fullAddress = `${address.number} ${fullAddress}`;
            }
            currentAddress = {
                address: fullAddress,
                postalCode: address.postalCode || '',
                city: address.townName || '',
                netArea: address.netArea,
            };
        } else {
            currentAddress = common.address;
        }
        const checkedNewAddress =
            common.newAddress &&
            (common.newAddress.address ||
                common.newAddress.postalCode ||
                common.newAddress.city);
        const checkedAddCoOwner =
            part.coOwner &&
            (part.coOwner.firstName !== '' || part.coOwner.lastName !== '');
        const checkedNewBillingAddress =
            common.newBillingAddress &&
            (common.newBillingAddress.name ||
                common.newBillingAddress.surname ||
                common.newBillingAddress.address ||
                common.newBillingAddress.postalCode ||
                common.newBillingAddress.city);

        this.state = {
            fields: {
                ...dataState,
                common: {
                    ...dataState.common,
                    address: currentAddress,
                },
            },
            setting: {
                checkedAddCoOwner,
                checkedNewAddress: checkedNewAddress !== null,
                checkedNewBillingAddress: checkedNewBillingAddress !== null,
                postalCodes: [],
                isLoadingPC: false,
                codeText: '',
                codeNewAddressText: '',
                codeNewBillingAddressText: '',
                showError: false,
                showBottomText: false,
                validSiret: null,
            },
        };

        this.searchService = new SearchService();
    }

    componentDidMount() {
        const { setting, fields } = this.state;
        const {
            validateDataScreenDispatch,
            validateDataScreenFinalDispatch,
            userState: { energyTypes },
            codeState: { PDL, PCE },
            dataState,
        } = this.props;
        this.searchService.getResults().subscribe(res => {
            if (res.length > 0) {
                setting.postalCodes = res.map(dt => ({
                    id: dt.code,
                    label: `${dt.code} - - - ${dt.city}`,
                    value: `${dt.code}__${dt.city}`,
                    codeValue: dt.code,
                    cityValue: dt.city,
                    netAreaValue: dt.netArea,
                }));
            } else {
                setting.postalCodes = [];
            }
            setting.isLoadingPC = false;
            this.setState({ setting });
        });

        DataScreen.navigationOptions.validate = () => {
            const modifiedFields = this.prepareField(fields);
            const reducer = (accumulator, currentValue) =>
                accumulator &&
                (currentValue === 'EL' ? PDL.length === 0 : PCE.length === 0);
            const condition = energyTypes.reduce(reducer, true);
            if (condition) {
                return validateDataScreenFinalDispatch(modifiedFields);
            }
            return validateDataScreenDispatch(modifiedFields);
        };

        DataScreen.navigationOptions.disabled = () => {
            setting.showError = true;
            this.setState({ setting });
        };

        DataScreen.navigationOptions.returnButton = () => {
            const { dataScreenSaveCommonDispatch } = this.props;
            dataScreenSaveCommonDispatch({ ...fields.common });
        };

        this.handleCondition();

        const {
            userState,
            fetchCalendarAppointmentDispatch,
            summaryState,
        } = this.props;
        fetchCalendarAppointmentDispatch({
            ...userState,
            ...summaryState,
        });

        const { common } = dataState;

        energyTypes.forEach(energy => {
            if (common.dateContract[energy].timeslot) {
                const newDate = new Date(
                    common.dateContract[energy].year,
                    common.dateContract[energy].month,
                    common.dateContract[energy].date
                );
                this.sendRequestTimeslot(newDate, energy);
            }
        });
    }

    componentDidUpdate() {
        this.handleCondition();
    }

    componentWillUnmount() {
        this.searchService.unsubscribe();
    }

    prepareField = fields => {
        const {
            setting: {
                checkedAddCoOwner,
                checkedNewAddress,
                checkedNewBillingAddress,
            },
        } = this.state;
        const modifiedFields = { ...fields };
        if (!checkedAddCoOwner) {
            modifiedFields.part = {
                coOwner: {
                    civility: 'MR',
                    firstName: '',
                    lastName: '',
                },
            };
        }
        if (!checkedNewAddress) {
            modifiedFields.common.newAddress = {
                address: null,
                postalCode: null,
                city: null,
                netArea: null,
            };
        }
        if (!checkedNewBillingAddress) {
            modifiedFields.common.newBillingAddress = {
                civility: 'MR',
                name: null,
                surname: null,
                address: null,
                postalCode: null,
                city: null,
                netArea: null,
            };
        }
        return modifiedFields;
    };

    handleCondition = () => {
        const { handleGoNext, userState } = this.props;
        const condition =
            userState.userType === UserScreenWording.userType.professional
                ? this.handleConditionPro()
                : this.handleConditionPart();
        if (handleGoNext) {
            if (condition) handleGoNext(true);
            else handleGoNext(false);
            return condition;
        }
        return condition;
    };

    handleConditionPro = () => {
        const {
            fields,
            checkedNewAddress,
            checkedNewBillingAddress,
            setting,
        } = this.state;

        let condition = this.handleConditionSituation();
        condition = condition && this.handleConditionCommon(fields.common);
        condition =
            condition &&
            this.handleConditionDataPro(fields.pro, setting.validSiret);
        if (!checkedNewAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newAddress);
        if (checkedNewBillingAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newBillingAddress);
        return condition;
    };

    handleConditionPart = () => {
        const {
            fields,
            setting: {
                checkedAddCoOwner,
                checkedNewAddress,
                checkedNewBillingAddress,
            },
        } = this.state;

        let condition = this.handleConditionSituation();
        condition =
            condition &&
            this.handleConditionCommon(fields.common) &&
            this.handleConditionAddress(fields.common.address);
        if (checkedAddCoOwner)
            condition =
                condition && this.handleConditionCoOwner(fields.part.coOwner);
        if (!checkedNewAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newAddress);
        if (checkedNewBillingAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newBillingAddress);
        return condition;
    };

    handleConditionSituation = () => {
        const {
            fields: {
                common: { dateContract },
            },
        } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        const reducer = (accumulator, energy) =>
            accumulator &&
            dateContract[energy] &&
            (dateContract[energy].date !== null &&
                dateContract[energy].month !== null &&
                dateContract[energy].year !== null &&
                (!dateContract.switch
                    ? dateContract[energy].timeslot !== ''
                    : true));

        return energyTypes.reduce(reducer, true);
    };

    handleConditionCoOwner = ({ firstName, lastName }) =>
        firstName !== '' && lastName !== '';

    handleConditionAddress = ({ address, city, postalCode }) =>
        address !== '' && city !== '' && postalCode !== '';

    handleConditionCommon = ({
        civility,
        name,
        surname,
        birthdate,
        email,
        tel,
    }) =>
        civility !== '' &&
        name !== '' &&
        surname !== '' &&
        birthdate !== '' &&
        email !== '' &&
        helpers.handleConditionEmail(email) &&
        tel !== null &&
        helpers.handleConditionPhone(tel);

    handleConditionDataPro = (
        { socialReason, legalForm, APECode, SIRET },
        validSiret
    ) =>
        socialReason !== '' &&
        legalForm !== '' &&
        APECode.length !== '' &&
        SIRET.length === 14 &&
        validSiret === 200;

    handleChangeInput = ({ target: { value } }, name, type) => {
        const { fields } = this.state;
        if (name === null) {
            switch (type) {
                case 'email':
                    fields.common.email = value;
                    break;
                case 'tel':
                    fields.common.tel = value.replace(/[^\d ]/g, '');
                    break;
                case 'SIRET': {
                    fields.pro.SIRET = value.replace(/[^\d ]/g, '');
                    if (fields.pro.SIRET.length === 14)
                        this.searchSiretRequest(value);
                    if (value === '') fields.pro.APECode = '';

                    break;
                }
                case 'APECode':
                    fields.pro.APECode = value;
                    break;
                case 'socialReason':
                    fields.pro.socialReason = value;
                    break;
                case 'coOwner':
                    fields.part.coOwner[type] = value;
                    break;
                default:
                    fields.common[type] = value;
                    break;
            }
        } else if (name === 'coOwner') {
            fields.part.coOwner[type] = value;
        } else {
            fields.common[name][type] = value;
        }
        this.setState({ fields });
    };

    handleDateChange = date => {
        const { fields } = this.state;
        if (date !== undefined) {
            fields.common.birthdate = date.toDateString();
            this.setState({ fields });
        }
    };

    handleChangeSelect = ({ value }, nameObj, type, ob) => {
        const { fields } = this.state;
        if (ob === undefined) fields[nameObj][type] = value;
        else fields[nameObj][type][ob] = value;
        this.setState({ fields });
    };

    handleInputChangeAddressContainer = (value, type) => {
        const { setting } = this.state;
        setting[type] = value.replace(/[^\d ]/g, '');
        if (value !== '') {
            setting.isLoadingPC = true;
        }

        const val = value.length > 5 ? value.substring(0, 4) : value;
        this.searchService.search(val);
        this.setState({ setting });
    };

    onFocusDropdown = (e, type) => {
        const { setting, fields } = this.state;
        if (type === 'address') {
            if (fields.common.address.postalCode !== '') {
                setting.codeText = fields.common.address.postalCode.toString();
            }
        } else if (type === 'newAddress') {
            if (fields.common.newAddress.postalCode) {
                setting.codeNewAddressText = fields.common.newAddress.postalCode.toString();
            }
        } else if (type === 'newBillingAddress') {
            if (fields.common.newBillingAddress.postalCode) {
                setting.codeNewBillingAddressText = fields.common.newBillingAddress.postalCode.toString();
            }
        }
        this.setState({ setting });
    };

    handleChangeDropdownSelect = (object, type) => {
        const { fields, setting } = this.state;
        const { codeValue, cityValue, netAreaValue } = object;
        if (type === 'address') {
            fields.common.address.city = cityValue;
            fields.common.address.postalCode = codeValue;
            fields.common.address.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'newAddress') {
            fields.common.newAddress.city = cityValue;
            fields.common.newAddress.postalCode = codeValue;
            fields.common.newAddress.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeNewAddressText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'newBillingAddress') {
            fields.common.newBillingAddress.city = cityValue;
            fields.common.newBillingAddress.postalCode = codeValue;
            fields.common.newBillingAddress.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeNewBillingAddressText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        }
    };

    handleDisplayValue = (value, type) => {
        if (type === 'tel') {
            return value.replace(/[^\d ]/g, '');
        }
        if (type === 'SIRET') {
            return value.replace(/[^\d ]/g, '');
        }
        return value;
    };

    handleToggle = ({ target: { checked } }, type) => {
        const { setting } = this.state;
        if (type === 'checkedNewAddress') {
            setting.checkedNewAddress = setting.checkedNewAddress !== checked;
        } else {
            setting[type] = !setting[type];
        }
        this.setState({ setting });
    };

    handleToggleSituation = ({ target: { value } }, type) => {
        const { fields, setting } = this.state;
        setting.showBottomText = false;
        fields.common[type] = value;
        const defaultValue = {
            date: null,
            month: null,
            year: null,
            timeslot: '',
            timeslots: [],
        };
        fields.common.dateContract = {
            EL: { ...defaultValue },
            NG: { ...defaultValue },
            switch: null,
        };
        this.setState({ fields, setting });
    };

    handleDatePicker = (date, type) => {
        const { fields, setting } = this.state;
        const featureDate = helpers.addBusinessDays(14);
        const newDate = new Date(date);
        if (fields.common.situation === Wording.situation.values.moved.value)
            setting.showBottomText = newDate.getTime() <= featureDate.getTime();
        if (newDate.getTime() > new Date().getTime()) {
            this.sendRequestTimeslot(newDate, type);
            const {
                dataState: { calendarAppointments },
            } = this.props;
            fields.common.dateContract[type].date = newDate.getDate();
            fields.common.dateContract[type].year = newDate.getFullYear();
            fields.common.dateContract[type].month = newDate.getMonth();
            const daysList = (
                calendarAppointments.find(c => c.energy === type) || {
                    daysList: [],
                }
            ).daysList;
            const formattedDate = newDate.toISOString().split('T')[0];
            const express = (
                daysList.find(d => d.date === formattedDate) || {
                    express: false,
                }
            ).express;
            fields.common.dateContract[type].express = express;
            this.setState({ fields, setting });
        }
    };

    sendRequestTimeslot = async (date, type) => {
        const { fields } = this.state;
        const ts = await searchAppointmentTimeslot(date, type);
        fields.common.dateContract[type].timeslots = ts.filter(
            t => t !== 'NONE'
        );
        this.setState({ fields });
    };

    searchSiretRequest = async value => {
        const { setting, fields } = this.state;
        const res = await searchSiret(value);
        if (res) {
            if (res.etablissement) {
                fields.pro.APECode = res.etablissement.activite_principale;
            }
            setting.validSiret = res.status;
            this.setState({ setting, fields });
        }
    };

    handleChangeTimeslotSelect = (e, type) => {
        const { fields } = this.state;
        fields.common.dateContract[type].timeslot = e.value;
        this.setState({ fields });
    };

    filterCalendarAppointmentResponse = (energyType, process) => {
        const { dataState } = this.props;
        const { calendarAppointments } = dataState;
        return calendarAppointments.filter(
            object => object.energy === energyType && object.process === process
        );
    };

    filterDateAppointment = ({ daysList: values }, type, condition) => {
        const arrSend = [];
        if (values !== undefined)
            values
                .filter(element =>
                    type === 'express'
                        ? element[type] === condition && element.available
                        : element[type] === condition
                )
                .forEach(val => arrSend.push(new Date(val.date)));
        if (type === 'available' && !condition) {
            const lastDay = values && new Date(values.slice(-1)[0].date);
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            arrSend.push({
                before: tomorrow,
                after: lastDay,
            });
        }
        return arrSend;
    };

    handleDateCalendarOption = (energyType, type) => {
        if (type === 'selected')
            return this.filterDateAppointment(
                ...this.filterCalendarAppointmentResponse(
                    energyType,
                    'MOVE_IN'
                ),
                'express',
                true
            );
        return this.filterDateAppointment(
            ...this.filterCalendarAppointmentResponse(energyType, 'MOVE_IN'),
            'available',
            false
        );
    };

    handleDropdownContract = object => {
        const { fields, setting } = this.state;
        const { dateContract } = fields.common;
        const {
            userState: { energyTypes },
        } = this.props;
        const actualDate = new Date();

        let day = 0;
        if (object.value === Wording.contractDate.dropdownValues[0].value) {
            day = 1;
            dateContract.switch = object;
            setting.showBottomText = true;
        } else if (
            object.value === Wording.contractDate.dropdownValues[1].value
        ) {
            day = 14;
            dateContract.switch = object;
            setting.showBottomText = false;
        }
        energyTypes.forEach(energy => {
            const newDate = helpers.addDays(actualDate, day);
            dateContract[energy].date = newDate.getDate();
            dateContract[energy].month = newDate.getMonth();
            dateContract[energy].year = newDate.getFullYear();
        });

        return this.setState({
            fields,
            setting,
        });
    };

    render() {
        const { userState, codeState } = this.props;
        const { fields, setting } = this.state;
        return (
            <DataContainer
                setting={setting}
                fields={fields}
                userState={userState}
                codeState={codeState}
                handleChangeSelect={this.handleChangeSelect}
                handleDisplayValue={this.handleDisplayValue}
                handleChangeInput={this.handleChangeInput}
                handleDatePicker={this.handleDatePicker}
                handleToggle={this.handleToggle}
                handleToggleSituation={this.handleToggleSituation}
                handleDropdownContract={this.handleDropdownContract}
                handleDateCalendarOption={this.handleDateCalendarOption}
                handleInputChangeAddressContainer={
                    this.handleInputChangeAddressContainer
                }
                handleChangeDropdownSelect={this.handleChangeDropdownSelect}
                handleChangeTimeslotSelect={this.handleChangeTimeslotSelect}
                onFocusDropdown={this.onFocusDropdown}
                handleDateChange={this.handleDateChange}
                condition={this.handleCondition}
            />
        );
    }
}

const mapStateToProps = state => ({
    dataState: state.dataReducer,
    userState: state.userReducer,
    codeState: state.codeReducer,
    summaryState: state.summaryReducer,
});

const mapDispatchToProps = dispatch => ({
    validateDataScreenDispatch: payload =>
        dispatch(actions.validateDataScreenSuccess(payload)),
    validateDataScreenFinalDispatch: payload =>
        dispatch(actions.validateDataScreenSuccessFinal(payload)),
    fetchCalendarAppointmentDispatch: payload =>
        dispatch(actions.fetchCalendarAppointment(payload)),
    dataScreenSaveCommonDispatch: payload =>
        dispatch(actions.dataScreenSaveCommon(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataScreen);
