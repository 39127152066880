import React from 'react';
import { CustomInput, CustomDropdownInput } from '../../components';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.AddressContainer;

const AddressContainer = ({
    addressValue,
    postalCodeValue,
    cityValue,
    handleInputAddress,
    options,
    isLoadingPC,
    handleInputPostalCode,
    handleChangeDropdownSelect,
    text,
    isLocked,
    inputValue,
    placeholder,
    onBlurDropdown,
    onFocusDropdown,
    showError,
}) => (
    <div className="address-container">
        {text && <p className="address-container__paragraph">{text}</p>}
        <div className="address-container__address-inputs">
            <CustomInput
                text={Wording.address}
                value={addressValue}
                onInputChange={handleInputAddress}
                isLocked={isLocked}
                error={
                    (addressValue === '' || addressValue === null) && showError
                        ? Wording.error.mainText
                        : null
                }
            />
            <CustomDropdownInput
                text={Wording.postalCode}
                isLoading={isLoadingPC}
                onInputChange={val => handleInputPostalCode(val)}
                options={options || []}
                placeholder={placeholder}
                handleChange={handleChangeDropdownSelect}
                value={postalCodeValue}
                isLocked={isLocked}
                inputValue={
                    inputValue.length <= 5
                        ? inputValue
                        : inputValue.substring(0, 5)
                }
                onBlur={onBlurDropdown}
                onFocus={onFocusDropdown}
                error={
                    postalCodeValue !== null
                        ? (postalCodeValue.label === '' ||
                              postalCodeValue.label === null) &&
                          showError &&
                          Wording.error.mainText
                        : Wording.error.mainText
                }
            />
            <CustomInput
                text={Wording.city}
                value={cityValue}
                onInputChange={() => {}}
                isLocked
            />
        </div>
    </div>
);

export default AddressContainer;
