import { combineEpics, ofType } from 'redux-observable';

import { of, throwError, merge } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { loadSurveyScreen } from '../SurveyScreen/SurveyScreen.actions';
import { mapGetOffer } from '../SummaryScreen/SummaryScreen.actions';

import {
    PACKAGE_SCREEN_VALIDATE,
    PACKAGE_SCREEN_VALIDATE_SUCCESS,
    PACKAGE_SCREEN_VALIDATE_FAILURE,
} from '../types';

import WordingConstant from '../../utils/wording';

const Wording = WordingConstant.Common;

const validatePackageScreen = payload => ({
    type: PACKAGE_SCREEN_VALIDATE,
    payload,
});

const validatePackageScreenSuccess = payload => ({
    type: PACKAGE_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validatePackageScreenFailure = payload => ({
    type: PACKAGE_SCREEN_VALIDATE_FAILURE,
    payload,
});

const preparePayloadGetProduct = ({
    userType,
    energyTypes,
    contracts,
    ...payload
}) => {
    const modifiedContracts = contracts.map(contract => {
        const { energy } = contract;
        const billing = {};
        if (payload[energy].cyclicBill) {
            billing.billingModeCode = Wording.billingModeCode.cyclic;
        } else {
            billing.billingModeCode = Wording.billingModeCode.schedule;
            billing.installmentFrequency = payload[energy].installmentFrequency;
        }
        return {
            ...contract,
            ...billing,
        };
    });
    return {
        contextOfUse: 'MARKET',
        customerType: userType,
        contracts: modifiedContracts,
    };
};

const mapGetProducts = (action, { apiRequest }) => {
    const payload = preparePayloadGetProduct(action.payload);
    return apiRequest({
        path: '/getProducts',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.productsList) {
                const chosenProducts = response.productsList.filter(
                    product => product.default
                );
                const modifiedAction = {
                    ...action,
                    payload: {
                        ...action.payload,
                        chosenProducts,
                        products: response.productsList,
                    },
                };
                return mapGetOffer(
                    modifiedAction,
                    { apiRequest },
                    validatePackageScreenSuccess,
                    validatePackageScreenFailure
                );
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        catchError(error =>
            of(validatePackageScreenFailure({ error: error.message }))
        )
    );
};

const validatePackageScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(PACKAGE_SCREEN_VALIDATE),
        mergeMap(action => mapGetProducts(action, dependency))
    );

const mapGetPackages = (
    action,
    { apiRequest },
    preparePayload,
    success,
    failure
) => {
    const payload = preparePayload(action.payload);
    return apiRequest({
        path: '/getPackages',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            const message = response.message || response.errorMessage;
            if (message) {
                return throwError({ message });
            }
            const packages = response.packagesList;
            if (action.payload.isFinal) {
                const { contracts } = action.payload;
                const modifiedContracts = response.contracts.map(contract => {
                    const currentContract = contracts.find(
                        c => c.energy === contract.energy
                    );
                    const notNullEstimate = contract.estimates.find(
                        es => es.quantity > 0
                    );
                    const estimates = notNullEstimate
                        ? contract.estimates
                        : currentContract.estimates;
                    return {
                        ...contract,
                        estimates,
                    };
                });
                const modifiedAction = {
                    ...action,
                    payload: {
                        ...action.payload,
                        contracts: modifiedContracts,
                    },
                };
                return merge(
                    mapGetProducts(modifiedAction, { apiRequest }),
                    of(
                        success({
                            ...action.payload,
                            packages,
                            prepaymentFrequencies:
                                response.prepaymentFrequencies,
                            autorizedBillingModes:
                                response.autorizedBillingModes,
                            contracts: response.contracts,
                        })
                    )
                );
            }
            if (packages) {
                const { energyTypes } = action.payload;
                const myFilter = function(e) {
                    return this.indexOf(e) < 0;
                };
                const array = energyTypes.filter(
                    myFilter,
                    packages.map(p => p.energy)
                );
                const condition = packages.length === 0 || array.length > 0;
                if (condition) {
                    return of(
                        loadSurveyScreen({
                            ...action.payload,
                            packages,
                            contracts: response.contracts,
                        })
                    );
                }
                return of(
                    success({
                        ...action.payload,
                        packages,
                        prepaymentFrequencies: response.prepaymentFrequencies,
                        autorizedBillingModes: response.autorizedBillingModes,
                        contracts: response.contracts,
                    })
                );
            }
            return of(
                loadSurveyScreen({
                    ...action.payload,
                    packages: [],
                    contracts: response.contracts,
                })
            );
        }),
        catchError(error =>
            of(
                failure({
                    error: error.message,
                })
            )
        )
    );
};

const packageScreenEpic = combineEpics(validatePackageScreenEpic);

export {
    validatePackageScreen,
    validatePackageScreenSuccess,
    packageScreenEpic,
    mapGetPackages,
};
