import React from 'react';
import { connect } from 'react-redux';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import CodeContainer from '../../containers/CodeContainer';
import * as actions from './CodeScreen.actions';
import Screen from '../screen';
import WordingConstant from '../../utils/wording';

const Wording = WordingConstant.CodeScreen;
const handleInputPDL$ = new Subject().pipe(debounceTime(1000));
const handleInputPCE$ = new Subject().pipe(debounceTime(1000));

export class CodeScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.PACKAGE,
        previousScreen: Screen.USER,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.CODE,
        showSaveButton: false,
        step: 1,
        validate: () => {},
        disabled: () => {},
    };

    constructor(props) {
        super(props);
        const {
            codeState: { PDL, PCE },
            mainState: { screenIds },
        } = props;
        const isFinal = screenIds.indexOf(Screen.SURVEY) >= 0;
        this.state = {
            isFinal,
            showError: false,
            fields: {
                PDL,
                PCE,
                errorPDL: '',
                errorPCE: '',
            },
        };
        if (isFinal) {
            CodeScreen.navigationOptions.step = 4;
            CodeScreen.navigationOptions.showSaveButton = true;
        } else {
            CodeScreen.navigationOptions.step = 1;
            CodeScreen.navigationOptions.showSaveButton = false;
        }
    }

    componentDidMount() {
        this.subscriptionPDL = handleInputPDL$.subscribe(value => {
            this.handleErrorInput(value, 'errorPDL');
        });
        this.subscriptionPCE = handleInputPCE$.subscribe(value => {
            this.handleErrorInput(value, 'errorPCE');
        });
        CodeScreen.navigationOptions.validate = () => {
            const { fields, isFinal } = this.state;
            const {
                userState: { userType, energyTypes },
                summaryState,
                validateCodeScreenDispatch,
                validateFinalCodeScreenDispatch,
            } = this.props;
            if (isFinal) {
                validateFinalCodeScreenDispatch({
                    ...fields,
                    ...summaryState,
                    userType,
                    energyTypes,
                });
            } else {
                validateCodeScreenDispatch({
                    ...fields,
                    userType,
                    energyTypes,
                });
            }
        };

        CodeScreen.navigationOptions.disabled = () => {
            const { fields } = this.state;

            if (fields.PDL.length < 14 || fields.PCE.length < 14) {
                this.handleErrorInput(null, 'errorPCE');
                this.handleErrorInput(null, 'errorPDL');
            }
            this.setState({ showError: true });
        };
        const {
            codeState: { PDL, PCE },
            userState: { energyTypes },
        } = this.props;
        this.handleCondition({ PDL, PCE, energyTypes });
    }

    componentDidUpdate() {
        const {
            fields: { PDL, PCE },
        } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        this.handleCondition({ PDL, PCE, energyTypes });
    }

    componentWillUnmount() {
        if (this.subscriptionPDL) {
            this.subscriptionPDL.unsubscribe();
        }
        if (this.subscriptionPCE) {
            this.subscriptionPCE.unsubscribe();
        }
    }

    handleCondition = ({ PDL, PCE, energyTypes }) => {
        const reducer = (accumulator, currentValue) =>
            accumulator &&
            (currentValue === 'EL' ? PDL.length === 14 : PCE.length === 14);
        const condition = energyTypes.reduce(reducer, true);
        const { handleGoNext } = this.props;
        if (handleGoNext) {
            if (condition) handleGoNext(true);
            else handleGoNext(false);
        }
        return condition;
    };

    handleErrorInput = (value, kind) => {
        const { fields } = this.state;
        const { handleGoNext } = this.props;
        if (value === null) {
            fields[kind] = Wording.inputError[kind];
        } else if (value.replace(/ /g, '').length !== 14) {
            fields[kind] = Wording.inputError[kind];
            handleGoNext(false);
        } else {
            fields[kind] = '';
            handleGoNext(true);
        }
        this.setState({ fields });
    };

    handleInputChange = ({ target: { value } }, codeType, action$) => {
        const { fields } = this.state;
        fields[codeType] = value.replace(/ /g, '');
        this.setState({ fields });
        action$.next(value);
    };

    handleDisplayCode = value =>
        value
            .replace(/[^\d ]/g, '')
            .replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4 $5');

    handleNoCode = () => {
        const { handleLoadScreen, resetCodeScreenDispatch } = this.props;
        resetCodeScreenDispatch({
            PDL: '',
            PCE: '',
        });
        handleLoadScreen(Screen.SURVEY);
    };

    render() {
        const {
            userState: { energyTypes },
        } = this.props;
        const {
            fields: { PDL, PCE, errorPDL, errorPCE },
            isFinal,
            showError,
        } = this.state;
        return (
            <div className="code-screen">
                <CodeContainer
                    energyTypes={energyTypes}
                    showError={showError}
                    PDL={this.handleDisplayCode(PDL)}
                    PCE={this.handleDisplayCode(PCE)}
                    handleCondition={this.handleCondition}
                    errorPDL={errorPDL}
                    errorPCE={errorPCE}
                    handleInputPDL={e =>
                        this.handleInputChange(e, 'PDL', handleInputPDL$)
                    }
                    handleInputPCE={e =>
                        this.handleInputChange(e, 'PCE', handleInputPCE$)
                    }
                    handleNoCode={this.handleNoCode}
                    isFinal={isFinal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    codeState: state.codeReducer,
    mainState: state.mainReducer,
    summaryState: state.summaryReducer,
});

const mapDispatchToProps = dispatch => ({
    resetCodeScreenDispatch: payload =>
        dispatch(actions.resetCodeScreen(payload)),
    validateCodeScreenDispatch: payload =>
        dispatch(actions.validateCodeScreen(payload)),
    validateFinalCodeScreenDispatch: payload =>
        dispatch(actions.validateFinalCodeScreen(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeScreen);
