import React from 'react';

import ImageHolder from '../ImageHolder';

const CustomRadio = ({
    text,
    value,
    id,
    checked,
    handleCheck,
    isImageVisible,
    description,
}) => (
    <label
        htmlFor={id}
        className={`custom-radio${
            isImageVisible ? ' custom-radio__label' : ''
        }`}
    >
        <input
            type="checkbox"
            id={id}
            checked={checked}
            onChange={handleCheck}
            value={value}
        />
        <span className="custom-radio__checkmark" />
        {isImageVisible && <ImageHolder />}
        {text}
        {description && (
            <p className="custom-radio__description">{description}</p>
        )}
    </label>
);

export default CustomRadio;
