export const USER_SCREEN_VALIDATE_SUCCESS = 'USER_SCREEN_VALIDATE_SUCCESS';
export const USER_SCREEN_RESET_RECOVER = 'USER_SCREEN_RESET_RECOVER';

export const CODE_SCREEN_RESET = 'CODE_SCREEN_RESET';
export const CODE_SCREEN_VALIDATE = 'CODE_SCREEN_VALIDATE';
export const CODE_SCREEN_VALIDATE_SUCCESS = 'CODE_SCREEN_VALIDATE_SUCCESS';
export const CODE_SCREEN_VALIDATE_FAILURE = 'CODE_SCREEN_VALIDATE_FAILURE';

export const FINAL_CODE_SCREEN_VALIDATE = 'FINAL_CODE_SCREEN_VALIDATE';
export const FINAL_CODE_SCREEN_VALIDATE_SUCCESS =
    'FINAL_CODE_SCREEN_VALIDATE_SUCCESS';
export const FINAL_CODE_SCREEN_VALIDATE_FAILURE =
    'FINAL_CODE_SCREEN_VALIDATE_FAILURE';

export const SURVEY_SCREEN_LOAD = 'SURVEY_SCREEN_LOAD';
export const SURVEY_SCREEN_VALIDATE = 'SURVEY_SCREEN_VALIDATE';
export const SURVEY_SCREEN_VALIDATE_SUCCESS = 'SURVEY_SCREEN_VALIDATE_SUCCESS';
export const SURVEY_SCREEN_VALIDATE_FAILURE = 'SURVEY_SCREEN_VALIDATE_FAILURE';

export const PACKAGE_SCREEN_VALIDATE = 'PACKAGE_SCREEN_VALIDATE';
export const PACKAGE_SCREEN_VALIDATE_SUCCESS =
    'PACKAGE_SCREEN_VALIDATE_SUCCESS';
export const PACKAGE_SCREEN_VALIDATE_FAILURE =
    'PACKAGE_SCREEN_VALIDATE_FAILURE';

export const FETCH_CALENDAR_APPOINTMENT = 'FETCH_CALENDAR_APPOINTMENT';
export const FETCH_CALENDAR_APPOINTMENT_SUCCESS =
    'FETCH_CALENDAR_APPOINTMENT_SUCCESS';
export const FETCH_CALENDAR_APPOINTMENT_FAILURE =
    'FETCH_CALENDAR_APPOINTMENT_FAILURE';

export const DATA_SCREEN_VALIDATE_SUCCESS = 'DATA_SCREEN_VALIDATE_SUCCESS';
export const DATA_SCREEN_VALIDATE_SUCCESS_FINAL =
    'DATA_SCREEN_VALIDATE_SUCCESS_FINAL';
export const DATA_SCREEN_SAVE_COMMON = 'DATA_SCREEN_SAVE_COMMON';

// export const SUMMARY_SCREEN_LOAD = 'SUMMARY_SCREEN_LOAD';
export const SUMMARY_SCREEN_VALIDATE_SUCCESS =
    'SUMMARY_SCREEN_VALIDATE_SUCCESS';
export const SUMMARY_SCREEN_CHANGE_OFFER = 'SUMMARY_SCREEN_CHANGE_OFFER';
export const SUMMARY_SCREEN_CHANGE_OPTION = 'SUMMARY_SCREEN_CHANGE_OPTION';
export const SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS =
    'SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS';
export const SUMMARY_SCREEN_CHANGE_OPTION_FAILURE =
    'SUMMARY_SCREEN_CHANGE_OPTION_FAILURE';
export const SUMMARY_SCREEN_GET_SPONSOR = 'SUMMARY_SCREEN_GET_SPONSOR';

export const FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES =
    'FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES';
export const FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES =
    'FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES';
export const FINAL_SUMMARY_SCREEN_VALIDATE = 'FINAL_SUMMARY_SCREEN_VALIDATE';
export const FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS =
    'FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS';
export const FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE =
    'FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE';

export const PAYMENT_SCREEN_LOAD = 'PAYMENT_SCREEN_LOAD';
export const PAYMENT_SCREEN_VALIDATE = 'PAYMENT_SCREEN_VALIDATE';
export const PAYMENT_SCREEN_VALIDATE_SUCCESS =
    'PAYMENT_SCREEN_VALIDATE_SUCCESS';
export const PAYMENT_SCREEN_VALIDATE_FAILURE =
    'PAYMENT_SCREEN_VALIDATE_FAILURE';

export const PREVIOUS_SCREEN_LOAD = 'PREVIOUS_SCREEN_LOAD';
export const NEXT_SCREEN_LOAD = 'NEXT_SCREEN_LOAD';
export const MAIN_SCREEN_SAVE_ORDER = 'MAIN_SCREEN_SAVE_ORDER';
export const MAIN_SCREEN_SAVE_ORDER_SUCCESS = 'MAIN_SCREEN_SAVE_ORDER_SUCCESS';
export const MAIN_SCREEN_SAVE_ORDER_FAILURE = 'MAIN_SCREEN_SAVE_ORDER_FAILURE';
export const MAIN_SCREEN_GET_ORDER = 'MAIN_SCREEN_GET_ORDER';
export const MAIN_SCREEN_GET_ORDER_SUCCESS = 'MAIN_SCREEN_GET_ORDER_SUCCESS';
export const MAIN_SCREEN_GET_ORDER_PACKAGE_SUCCESS =
    'MAIN_SCREEN_GET_ORDER_PACKAGE_SUCCESS';
export const MAIN_SCREEN_GET_ORDER_PRODUCT_SUCCESS =
    'MAIN_SCREEN_GET_ORDER_PRODUCT_SUCCESS';
export const MAIN_SCREEN_GET_ORDER_FAILURE = 'MAIN_SCREEN_GET_ORDER_FAILURE';
export const MAIN_SCREEN_GET_PACKAGE_FAILURE =
    'MAIN_SCREEN_GET_PACKAGE_FAILURE';
export const MAIN_SCREEN_GET_OFFER_FAILURE = 'MAIN_SCREEN_GET_OFFER_FAILURE';
export const MAIN_SCREEN_GET_PRODUCT_FAILURE =
    'MAIN_SCREEN_GET_PRODUCT_FAILURE';
