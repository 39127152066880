import React from 'react';
import { connect } from 'react-redux';

import PackageContainer from '../../containers/PackageContainer';
import { validatePackageScreen } from './PackageScreen.actions';
import Screen from '../screen';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PackageScreen;

class PackageScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.SUMMARY,
        previousScreen: Screen.CODE,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.PACKAGE,
        showSaveButton: false,
        step: 2,
        validate: () => {},
    };

    constructor(props) {
        super(props);
        const { packageState, summaryState } = props;
        const cyclicBillingMode =
            packageState.autorizedBillingModes &&
            packageState.autorizedBillingModes.find(
                mode => mode.billingModeCode === Wording.billingMode.cyclic
            );
        const scheduleBillingMode =
            packageState.autorizedBillingModes &&
            packageState.autorizedBillingModes.find(
                mode => mode.billingModeCode === Wording.billingMode.schedule
            );
        const fields = {};
        ['EL', 'NG'].forEach(energy => {
            fields[energy] = {
                installmentFrequency: summaryState[energy].installmentFrequency,
                cyclicBill: summaryState[energy].cyclicBill,
                chosenPackages: summaryState[energy].chosenPackages,
                packageID: summaryState[energy].packageID,
            };
        });

        this.state = {
            fields,
            setting: {
                showError: false,
            },
            cyclicBillingMode,
            scheduleBillingMode,
        };
    }

    componentDidMount() {
        const {
            validatePackageScreenDispatch,
            userState: { userType, energyTypes },
            summaryState: { contracts, EL, NG },
        } = this.props;

        PackageScreen.navigationOptions.validate = () => {
            const { fields } = this.state;
            validatePackageScreenDispatch({
                ...fields,
                userType,
                energyTypes,
                contracts,
            });
        };

        PackageScreen.navigationOptions.disabled = () => {
            const { setting } = this.state;
            setting.showError = true;
            this.setState({ setting });
        };

        PackageScreen.navigationOptions.title =
            energyTypes.length > 1 ? Wording.secondaryTitle : Wording.title;

        const fields = {
            EL,
            NG,
        };
        this.handleCondition({ fields, energyTypes });
    }

    componentDidUpdate() {
        const { fields } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        this.handleCondition({ fields, energyTypes });
    }

    handleCondition = ({ fields, energyTypes }) => {
        const reducer = (accumulator, currentValue) => {
            const { installmentFrequency, packageID, cyclicBill } = fields[
                currentValue
            ];
            return (
                accumulator &&
                ((installmentFrequency !== '' && packageID !== '') ||
                    cyclicBill)
            );
        };
        const condition = energyTypes.reduce(reducer, true);
        const { handleGoNext } = this.props;
        if (handleGoNext) {
            if (condition) {
                handleGoNext(true);
            } else {
                handleGoNext(false);
            }
        }
    };

    handleChoosePackage = (id, kind) => {
        const { fields, cyclicBillingMode } = this.state;
        const {
            packageState: { packages },
        } = this.props;
        if (cyclicBillingMode && fields[kind].cyclicBill) {
            return;
        }
        fields[kind].packageID = id;
        const pkg = packages.find(p => p.id === id);
        fields[kind].chosenPackages = pkg ? [pkg] : [];
        this.setState({ fields });
    };

    handleChangeInstallmentFrequency = (e, kind) => {
        const { value } = e.target;
        const { fields, cyclicBillingMode } = this.state;
        if (cyclicBillingMode && fields[kind].cyclicBill) {
            return;
        }
        fields[kind].installmentFrequency = value;
        fields[kind].cyclicBill = false;
        this.setState({ fields });
    };

    handleChangeCyclicBill = kind => {
        const { fields } = this.state;
        fields[kind].cyclicBill = !fields[kind].cyclicBill;
        fields[kind].packageID = '';
        fields[kind].chosenPackages = [];
        fields[kind].installmentFrequency = '';
        this.setState({ fields });
    };

    render() {
        const {
            userState: { energyTypes },
            packageState: { packages, prepaymentFrequencies },
        } = this.props;
        const {
            fields,
            setting: { showError },
            cyclicBillingMode,
            scheduleBillingMode,
        } = this.state;
        return (
            <div className="package-screen">
                <h3 className="package-screen__description">
                    {Wording.description}
                </h3>
                {energyTypes &&
                    energyTypes.map((energy, index) => {
                        const obj = fields[energy];
                        const packageList = packages.filter(
                            p => p.energy === energy
                        );
                        return (
                            <PackageContainer
                                key={index}
                                packageTitle={Wording.subtitle[energy]}
                                packageList={packageList}
                                onChangePaymentFrequency={e =>
                                    this.handleChangeInstallmentFrequency(
                                        e,
                                        energy
                                    )
                                }
                                onChoosePackage={id =>
                                    this.handleChoosePackage(id, energy)
                                }
                                paymentFrequency={obj.installmentFrequency}
                                prepaymentFrequencies={prepaymentFrequencies}
                                cyclicBillingMode={cyclicBillingMode}
                                scheduleBillingMode={scheduleBillingMode}
                                packageID={obj.packageID}
                                kind={energy}
                                cyclicBill={obj.cyclicBill}
                                showError={showError}
                                onChangeCyclicBill={() =>
                                    this.handleChangeCyclicBill(energy)
                                }
                                showSeparator={
                                    index === 0 && energyTypes.length > 1
                                }
                            />
                        );
                    })}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
});

const mapDispatchToProps = dispatch => ({
    validatePackageScreenDispatch: payload =>
        dispatch(validatePackageScreen(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PackageScreen);
