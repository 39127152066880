import {
    CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    SURVEY_SCREEN_LOAD,
} from '../types';

import * as helpers from '../../utils/helpers';

export const initialState = {
    packages: [],
    prepaymentFrequencies: [],
    autorizedBillingModes: [],
};

const recoverState = (
    state,
    { packages, prepaymentFrequencies, autorizedBillingModes }
) => ({
    ...state,
    prepaymentFrequencies,
    autorizedBillingModes,
    packages,
});

function packageReducer(state = initialState, action) {
    switch (action.type) {
        case CODE_SCREEN_VALIDATE_SUCCESS:
        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const {
                prepaymentFrequencies,
                autorizedBillingModes,
                packages,
            } = action.payload;
            return {
                ...state,
                packages,
                prepaymentFrequencies,
                autorizedBillingModes,
            };
        }
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS: {
            const {
                prepaymentFrequencies,
                autorizedBillingModes,
                packages,
            } = action.payload;
            const modifiedPackages = helpers.mergeArray(
                packages,
                state.packages
            );
            return {
                ...state,
                packages: modifiedPackages,
                prepaymentFrequencies,
                autorizedBillingModes,
            };
        }
        case SURVEY_SCREEN_LOAD: {
            return {
                ...state,
                packages: [...state.packages, ...action.payload.packages],
            };
        }
        case MAIN_SCREEN_GET_ORDER_SUCCESS:
            return recoverState(state, action.payload);
        default:
            return state;
    }
}

export default packageReducer;
