import React, { Component } from 'react';

class CustomDropdown extends Component {
    constructor(props) {
        super(props);
        const { items, selectedItem } = this.props;
        this.state = {
            items: items || [],
            selectedItem: selectedItem || items[0],
            showItems: false,
        };
    }

    dropDown = () => {
        this.setState(prevState => ({
            showItems: !prevState.showItems,
        }));
    };

    selectItem = item => {
        const { handleDropdown } = this.props;
        handleDropdown(item);
        this.setState({
            selectedItem: item,
            showItems: false,
        });
    };

    render() {
        const { selectedItem, showItems, items, name } = this.state;
        return (
            <div>
                <div className="select-box--box">
                    <div
                        role="button"
                        className="select-box--container"
                        onClick={this.dropDown}
                        onKeyPress={() => {}}
                        tabIndex="0"
                    >
                        <div className="select-box--selected-item">
                            {selectedItem.value}
                        </div>
                    </div>
                    <div
                        className="select-box--items"
                        style={{
                            display: showItems ? 'block' : 'none',
                        }}
                    >
                        {items.map(item => (
                            <div
                                role="button"
                                key={item.id}
                                onClick={() => this.selectItem(item)}
                                className={
                                    selectedItem === item ? 'selected' : ''
                                }
                                onKeyPress={() => {}}
                                tabIndex="0"
                            >
                                {item.value}
                            </div>
                        ))}
                    </div>
                </div>
                <input type="hidden" name={name} value={selectedItem.id} />
            </div>
        );
    }
}

export default CustomDropdown;
