import React from 'react';

const CustomToggle = ({ text, value, checked, id, handleCheck }) => (
    <div className="custom-toggle">
        <input
            type="checkbox"
            id={id}
            name=""
            value={value}
            checked={checked}
            onChange={handleCheck}
        />
        <div className="custom-toggle__toggle">
            <label htmlFor={id}>
                <i />
            </label>
        </div>
        <p>{text}</p>
    </div>
);

export default CustomToggle;
