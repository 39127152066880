import React from 'react';

const CustomButton = ({ title, color, onClick, arrowLeft, arrowRight }) => (
    <button
        className={`custom-button custom-button--${color}`}
        type="button"
        onClick={onClick}
    >
        {arrowLeft && <i className="custom-button__arrowLeft" />}
        {title}
        {arrowRight && <i className="custom-button__arrowRight" />}
    </button>
);

export default CustomButton;
