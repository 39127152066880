import { combineEpics, ofType } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { loadPaymentScreen } from '../PaymentScreen/PaymentScreen.actions';
import {
    FINAL_SUMMARY_SCREEN_VALIDATE,
    FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS,
    FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE,
    FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES,
    FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES,
} from '../types';

import { getDateString, getTodayDateString } from '../../utils/helpers';

import WordingConstant from '../../utils/wording';

const Wording = WordingConstant.Common;

const validateFinalSummaryScreen = payload => ({
    type: FINAL_SUMMARY_SCREEN_VALIDATE,
    payload,
});

const validateFinalSummaryScreenSuccess = payload => ({
    type: FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validateFinalSummaryScreenFailure = payload => ({
    type: FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE,
    payload,
});

const changePackages = payload => ({
    type: FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES,
    payload,
});

const changeAdditionalRates = payload => ({
    type: FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES,
    payload,
});

const formatAddress = address => {
    const modifiedAddress = { ...address };
    const sliceIndexAddressStreet = address.street.indexOf(' ');
    if (sliceIndexAddressStreet > 0) {
        modifiedAddress.number = address.street.substring(
            0,
            sliceIndexAddressStreet
        );
        modifiedAddress.street = address.street.substring(
            sliceIndexAddressStreet + 1
        );
    }
    return modifiedAddress;
};

const prepareOrder = ({
    userType,
    energyTypes,
    common,
    part,
    pro,
    finance,
    contracts,
    thirdParty,
    reference,
    externalReference,
    status,
    ...payload
}) => {
    let address = {
        street: common.address.address,
        postalCode: `${common.address.postalCode}`,
        townName: common.address.city,
    };
    if (
        common.newAddress.address &&
        common.newAddress.postalCode &&
        common.newAddress.city
    ) {
        address.street = common.newAddress.address;
        address.postalCode = `${common.newAddress.postalCode}`;
        address.townName = common.newAddress.city;
    }
    address = formatAddress(address);
    const acDt = new Date(common.birthdate);
    const contact = {
        address,
        civility: common.civility,
        firstName: common.name,
        lastName: common.surname,
        birthdate: getDateString({
            year: acDt.getFullYear(),
            month: acDt.getMonth(),
            date: acDt.getDate(),
        }),
        email: common.email,
        phone: common.tel,
    };
    if (
        pro.socialReason !== '' &&
        pro.legalForm !== '' &&
        pro.APECode !== '' &&
        pro.SIRET !== ''
    ) {
        contact.companyName = pro.socialReason;
        contact.companyType = pro.legalForm;
        contact.activityCode = pro.APECode;
        contact.identificationNumber = pro.SIRET;
    }
    let modifiedFinance = {
        paymentMode: 'DIRECT_DEBIT',
        accountOwnerFirstName: common.name,
        accountOwnerLastName: common.surname,
    };
    if (finance) {
        modifiedFinance = { ...finance };
    }
    if (
        common.newBillingAddress.address &&
        common.newBillingAddress.postalCode &&
        common.newBillingAddress.city
    ) {
        let billingAddress = {
            street: common.newBillingAddress.address,
            postalCode: `${common.newBillingAddress.postalCode}`,
            townName: common.newBillingAddress.city,
        };
        billingAddress = formatAddress(billingAddress);
        modifiedFinance.billingContact = {
            civility: common.newBillingAddress.civility,
            firstName: common.newBillingAddress.name,
            lastName: common.newBillingAddress.surname,
            address: billingAddress,
        };
        modifiedFinance.accountOwnerFirstName = common.newBillingAddress.name;
        modifiedFinance.accountOwnerLastName = common.newBillingAddress.surname;
    }
    const filteredContracts =
        contracts &&
        contracts.filter(contract => energyTypes.includes(contract.energy));
    const modifiedContracts =
        filteredContracts &&
        filteredContracts.map(contract => {
            const {
                chosenPackages,
                offer,
                additionalOptions,
                cyclicBill,
            } = payload[contract.energy];
            const billingModeCode = cyclicBill
                ? Wording.billingModeCode.cyclic
                : Wording.billingModeCode.schedule;
            const process = {
                type: common.situation,
                express: common.dateContract[contract.energy].express,
                appointmentTimeslot:
                    common.dateContract[contract.energy].timeslot,
            };
            const effectiveStartRange = (common.dateContract.switch || {})
                .value;
            const thirdParties = [];
            if (thirdParty && !thirdParty.error && thirdParty.code !== '') {
                thirdParties.push({
                    role: 'SPONSOR',
                    code: thirdParty.code,
                });
            }
            const coholder = part.coOwner;
            if (
                coholder &&
                coholder.firstName !== '' &&
                coholder.lastName !== ''
            ) {
                thirdParties.push({
                    role: 'COHOLDER',
                    code: '',
                    civility: coholder.civility,
                    firstName: coholder.firstName,
                    lastName: coholder.lastName,
                });
            }
            const effectiveStartDate = getDateString(
                common.dateContract[contract.energy]
            );
            const subscriptionDate = getTodayDateString();
            const chosenProduct = { ...offer.product };

            if (offer.product && additionalOptions) {
                if (offer.product.additionalRates) {
                    // if (isSignOrder) {
                    //     chosenProduct.additionalRates = offer.product.additionalRates
                    //         .filter(rate => {
                    //             if (rate.type !== 'OPTION') {
                    //                 return true;
                    //             }
                    //             return (
                    //                 additionalOptions.indexOf(rate.label) !== -1
                    //             );
                    //         })
                    //         .map(rate => {
                    //             if (rate.type !== 'OPTION') {
                    //                 return rate;
                    //             }
                    //             return { ...rate, active: true };
                    //         });
                    // } else {
                    chosenProduct.additionalRates = offer.product.additionalRates.map(
                        rate => {
                            if (rate.type !== 'OPTION') {
                                return rate;
                            }
                            const active =
                                additionalOptions.indexOf(rate.label) !== -1;
                            return {
                                ...rate,
                                active,
                            };
                        }
                    );
                    // }
                } else {
                    chosenProduct.additionalRates = [];
                }
            }
            return {
                ...contract,
                thirdParties,
                chosenProduct,
                effectiveStartRange,
                effectiveStartDate,
                subscriptionDate,
                billingModeCode,
                chosenPackages: chosenPackages || [],
                installmentFrequency:
                    payload[contract.energy].installmentFrequency,
                deliveryPoint: {
                    ...contract.deliveryPoint,
                    process,
                },
            };
        });
    const order = {
        contracts: modifiedContracts,
        customer: {
            contact,
            finance: modifiedFinance,
            type: userType,
        },
    };
    if (reference) {
        // In case of FAIL
        // use the same order reference
        return {
            ...order,
            externalReference,
            orderReference: reference,
            orderStatus: status,
        };
    }

    return order;
};

const preparePayload = payload => {
    const order = prepareOrder(payload);
    return {
        order,
    };
};

const mapGetPayment = (action, { apiRequest }) => {
    const payload = preparePayload(action.payload);
    return apiRequest({
        path: '/signOrder',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.iframe) {
                return of(
                    loadPaymentScreen(response),
                    validateFinalSummaryScreenSuccess(action.payload)
                );
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        catchError(error =>
            of(
                validateFinalSummaryScreenFailure({
                    error: error.message,
                })
            )
        )
    );
};

const validateFinalSummaryScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(FINAL_SUMMARY_SCREEN_VALIDATE),
        mergeMap(action => mapGetPayment(action, dependency))
    );

const finalSummaryScreenEpic = combineEpics(validateFinalSummaryScreenEpic);

export {
    validateFinalSummaryScreen,
    validateFinalSummaryScreenSuccess,
    validateFinalSummaryScreenFailure,
    finalSummaryScreenEpic,
    prepareOrder,
    changeAdditionalRates,
    changePackages,
};
