import React from 'react';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Uppli;

const Footer = () => (
    <div className="footer">
        <div className="footer__certification">
            <a
                href={Wording.certification.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src={resources['ic-certification']}
                    alt="ic-certification"
                />
            </a>
            <div className="footer__certification__text">
                <p>{Wording.certification.text1}</p>
                <p>{Wording.certification.text2}</p>
            </div>
        </div>

        <div className="footer__articles">
            {Wording.articles.map((e, index) => {
                const show = index < Wording.articles.length - 1;
                return (
                    <React.Fragment key={index}>
                        <a
                            href={e.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {e.text}
                        </a>
                        {show && <p>|</p>}
                    </React.Fragment>
                );
            })}
        </div>

        <div className="footer__social">
            <a
                href={Wording.socialLink.linkedin}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={resources['ic-linkedin']} alt="ic-linkedin" />
            </a>
            <a
                href={Wording.socialLink.instagram}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={resources['ic-instagram']} alt="ic-instagram" />
            </a>
            <a
                href={Wording.socialLink.facebook}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={resources['ic-facebook']} alt="ic-facebook" />
            </a>
            <a
                href={Wording.socialLink.youtube}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={resources['ic-youtube']} alt="ic-youtube" />
            </a>
            <a
                href={Wording.socialLink.twitter}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={resources['ic-twitter']} alt="ic-twitter" />
            </a>
        </div>
    </div>
);

export default Footer;
