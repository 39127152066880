import React from 'react';

import { CustomButton, Timeline } from '../../components';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Common;

const FooterContainer = ({
    previousScreen,
    nextScreen,
    buttonPreviousTitle,
    buttonNextTitle,
    validate,
    error,
    canGoNext,
    handlePreviousScreen,
    handleLoadScreen,
    handleDisabledValidate,
    handleReturnButton,
    handleSave,
    showSaveButton,
    showTimeline,
    step,
    disabled,
    returnButton,
    canComeBack,
}) => (
    <div className="footer-container">
        {error && (
            <div className="footer-container__error">
                <p>{Wording.errorTitle}</p>
                <p>{error}</p>
            </div>
        )}
        <div className="footer-container__navigation-buttons">
            {previousScreen && !canComeBack && (
                <CustomButton
                    title={buttonPreviousTitle}
                    color="gray"
                    arrowLeft
                    onClick={() => {
                        handleReturnButton(returnButton);
                        handlePreviousScreen();
                    }}
                />
            )}
            {nextScreen && (
                <CustomButton
                    title={buttonNextTitle}
                    color={canGoNext ? 'orange' : 'gray'}
                    onClick={() =>
                        canGoNext
                            ? handleLoadScreen(nextScreen, validate)
                            : handleDisabledValidate(disabled)
                    }
                />
            )}
        </div>
        {showSaveButton && (
            <div className="footer-container__save-button">
                <CustomButton
                    title={Wording.footer.saveButton}
                    color="white"
                    onClick={handleSave}
                />
                <p>{Wording.footer.text}</p>
            </div>
        )}

        {showTimeline && (
            <section className="footer-container__timeline">
                <Timeline items={Wording.footer.timeline} step={step} />
            </section>
        )}
    </div>
);

export default FooterContainer;
