import React from 'react';
import Select from 'react-select';

const CustomDropdownInput = ({
    id,
    text,
    value,
    defaultValue,
    handleChange,
    onInputChange,
    options,
    styles,
    isLoading,
    placeholder,
    isLocked,
    inputValue,
    onKeyDown,
    onBlur,
    onFocus,
    error,
}) => (
    <div className="custom-dropdown-input">
        <label htmlFor={id}>{text}</label>

        <div className="custom-dropdown-input__div">
            <Select
                className="custom-dropdown-input__select"
                classNamePrefix="react-select"
                isLoading={isLoading}
                onInputChange={onInputChange}
                styles={styles}
                isDisabled={isLocked}
                value={value}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={handleChange}
                options={options}
                inputValue={inputValue}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onFocus={onFocus}
            />

            {error && error !== '' && (
                <p className="custom-dropdown-input__div__error">{error}</p>
            )}
        </div>
    </div>
);

export default CustomDropdownInput;
