import React from 'react';
import { CustomModal, CustomInput } from '../../components';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.CodeScreen;

class CodeContainer extends React.Component {
    state = {
        showModal: false,
    };

    renderInput = (energyTypes, input, isChecked, showError) => {
        const objs = energyTypes.map(energy => input[energy]);
        const { showModal } = this.state;
        return (
            <div className="code-container__identity__inputs">
                {objs &&
                    objs.map(
                        (
                            { text, value, error, placeholder, onInputChange },
                            index
                        ) => (
                            <CustomInput
                                key={index}
                                isLocked={isChecked}
                                text={text}
                                type="text"
                                value={value}
                                error={showError && error}
                                placeholder={placeholder}
                                onInputChange={onInputChange}
                                onToggleModal={() =>
                                    this.setState({ showModal: !showModal })
                                }
                                isInterrogationVisible
                                maxLength={14}
                                minLength={14}
                            />
                        )
                    )}
            </div>
        );
    };

    renderModal() {
        const { showModal } = this.state;
        return (
            <CustomModal
                show={showModal}
                showCloseButton
                handleClick={() => this.setState({ showModal: false })}
            >
                <div className="modal">
                    <label htmlFor="idPopup">
                        <i className="custom-input__layer">?</i>
                        {Wording.popup.title}
                    </label>
                    <div className="modal__content">
                        <img
                            src={resources[Wording.popup.imageSrc]}
                            alt="modal pdl pce"
                        />
                        <div>
                            {Wording.popup.descriptions &&
                                Wording.popup.descriptions.map(
                                    (desc, index) => <p key={index}>{desc}</p>
                                )}
                        </div>
                    </div>
                </div>
            </CustomModal>
        );
    }

    renderNoCode = (energyTypes, handleNoCode) => (
        <div className="code-container__identity__noCode">
            <span className="code-container__identity__separator">OU</span>
            <div
                className="code-container__identity__link"
                onClick={handleNoCode}
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
            >
                {energyTypes.length === 1
                    ? Wording.noCodeTitle[energyTypes[0]]
                    : Wording.noCodeTitle.ELNG}
            </div>
        </div>
    );

    render() {
        const {
            energyTypes,
            PDL,
            PCE,
            errorPDL,
            errorPCE,
            isChecked,
            handleInputPDL,
            handleInputPCE,
            handleNoCode,
            handleCondition,
            isFinal,
            showError,
        } = this.props;

        const input = {
            EL: {
                text: Wording.codeTitle.EL,
                value: PDL,
                error: errorPDL,
                onInputChange: handleInputPDL,
                placeholder: Wording.placeholder.EL,
            },
            NG: {
                text: Wording.codeTitle.NG,
                value: PCE,
                error: errorPCE,
                onInputChange: handleInputPCE,
                placeholder: Wording.placeholder.NG,
            },
        };

        return (
            <div className="code-container">
                {this.renderModal()}
                <div className="code-container__identity">
                    {this.renderInput(energyTypes, input, isChecked, showError)}

                    {!isFinal && this.renderNoCode(energyTypes, handleNoCode)}
                    {!isFinal && (
                        <p className="code-container__identity__paragraph">
                            {Wording.footer}
                        </p>
                    )}
                </div>

                {showError &&
                    !handleCondition({
                        PDL: PDL.replace(/\s/g, ''),
                        PCE: PCE.replace(/\s/g, ''),
                        energyTypes,
                    }) && (
                        <p className="code-container__error">
                            {Wording.inputError.bottomText}
                        </p>
                    )}
            </div>
        );
    }
}

export default CodeContainer;
