import { Subject, of, from, throwError } from 'rxjs';
import {
    debounceTime,
    switchMap,
    distinctUntilChanged,
    mergeMap,
    catchError,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import Config from './config';
import { log } from './helpers';

const errorMessage = "Votre code parrainage n'est pas valide.";
const successMessage = 'Votre code parrainage est valide.';
class SearchService {
    constructor() {
        this.handleSearch$ = new Subject().pipe(debounceTime(100));
    }

    unsubscribe = () => {
        this.handleSearch$.unsubscribe();
    };

    search = term => {
        this.handleSearch$.next(term);
    };

    doSearch = term => {
        const settings = {
            url: `${Config.URL}/getTowns`,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': Config.ApiKey,
            },
            responseType: 'json',
            method: 'post',
            crossDomain: true,
            body: {
                postalCode: term,
            },
        };
        const promise = ajax(settings).pipe(
            catchError(({ message }) => throwError({ error: message })),
            mergeMap(response =>
                of(
                    response.response
                        ? response.response.map(res => ({
                              code: res.postalCode,
                              city: res.townName,
                              netArea: res.netArea,
                          }))
                        : []
                )
            )
        );
        return from(promise);
    };

    getResults() {
        return this.handleSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(term => (term ? this.doSearch(term) : of([]))),
            catchError(() => of([]))
        );
    }

    doSearchParenting = customerNbr => {
        const settings = {
            url: `${Config.URL_CS}/getContext`,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': Config.ApiKey,
            },
            responseType: 'json',
            method: 'post',
            crossDomain: true,
            body: {
                customerNbr,
            },
        };
        log(`FO Data: POST /getContext ====> `, { customerNbr });
        const promise = ajax(settings).pipe(
            catchError(() =>
                throwError({
                    code: 1,
                    message: errorMessage,
                    value: customerNbr,
                })
            ),
            mergeMap(response => {
                log(`BO Response: POST /getContext ====> `, response.response);
                const res =
                    response && response.response && response.response.customer
                        ? {
                              code: 0,
                              message: successMessage,
                              value: customerNbr,
                          }
                        : {
                              code: 1,
                              message: errorMessage,
                              value: customerNbr,
                          };
                return of(res);
            })
        );
        return from(promise);
    };

    getParentingResult() {
        return this.handleSearch$.pipe(
            debounceTime(800),
            distinctUntilChanged(),
            switchMap(term =>
                term
                    ? this.doSearchParenting(term)
                    : of({ code: 1, message: errorMessage, value: term })
            ),
            catchError(() => of({ code: 1, message: errorMessage }))
        );
    }
}

export default SearchService;
