import React from 'react';
import Select from 'react-select';

class CustomSelect extends React.Component {
    state = {
        isMenuOpen: false,
    };

    render() {
        const {
            id,
            text,
            value,
            defaultValue,
            handleChange,
            options,
            placeholder,
            error,
        } = this.props;
        const current = options.find(option => option.value === defaultValue);
        const currentValue =
            options.find(option => option.value === value) || current;
        const { isMenuOpen } = this.state;
        return (
            <div className="custom-select">
                <label htmlFor={id}>{text}</label>

                <div className="custom-select__div">
                    <Select
                        className={`custom-select__select ${
                            isMenuOpen ? 'custom-select__select--menuOpen' : ''
                        }`}
                        classNamePrefix="react-select"
                        value={currentValue}
                        placeholder={placeholder || (current || {}).label}
                        defaultValue={defaultValue}
                        onChange={handleChange}
                        options={options}
                        onMenuOpen={() => this.setState({ isMenuOpen: true })}
                        onMenuClose={() => this.setState({ isMenuOpen: false })}
                    />

                    {error && error !== '' && (
                        <p className="custom-select__div__error">{error}</p>
                    )}
                </div>
            </div>
        );
    }
}

export default CustomSelect;
