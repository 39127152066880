import {
    SURVEY_SCREEN_VALIDATE,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
} from '../types';

export const initialState = {
    postalCode: '',
    city: '',
    netArea: '',
    housingType: '',
    residenceType: '',
    area: 20,
    inhabitantsNumber: 1,
    heatingType: '',
    priceOption: '',
};

function surveyReducer(state = initialState, action) {
    switch (action.type) {
        case SURVEY_SCREEN_VALIDATE:
            return {
                ...state,
            };
        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            return {
                ...state,
                postalCode: action.payload.postalCode,
                city: action.payload.city,
                netArea: action.payload.netArea,
                housingType: action.payload.housingType,
                residenceType: action.payload.residenceType,
                area: action.payload.area,
                inhabitantsNumber: action.payload.inhabitantsNumber,
                heatingType: action.payload.heatingType,
                priceOption: action.payload.priceOption,
            };
        }
        default:
            return state;
    }
}

export default surveyReducer;
