const Screen = Object.freeze({
    USER: 'UserScreen',
    CODE: 'CodeScreen',
    SURVEY: 'SurveyScreen',
    PACKAGE: 'PackageScreen',
    SUMMARY: 'SummaryScreen',
    DATA: 'DataScreen',
    FINALSUMMARY: 'FinalSummaryScreen',
    PAYMENT: 'PaymentScreen',
    FINAL: 'FinalScreen',
    SAVE: 'SaveScreen',
});

export default Screen;
