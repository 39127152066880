import {
    PREVIOUS_SCREEN_LOAD,
    NEXT_SCREEN_LOAD,
    MAIN_SCREEN_SAVE_ORDER,
    MAIN_SCREEN_SAVE_ORDER_SUCCESS,
    MAIN_SCREEN_SAVE_ORDER_FAILURE,
    MAIN_SCREEN_GET_ORDER,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    MAIN_SCREEN_GET_ORDER_FAILURE,
    MAIN_SCREEN_GET_OFFER_FAILURE,
    MAIN_SCREEN_GET_PACKAGE_FAILURE,
    MAIN_SCREEN_GET_PRODUCT_FAILURE,
    USER_SCREEN_VALIDATE_SUCCESS,
    USER_SCREEN_RESET_RECOVER,
    CODE_SCREEN_VALIDATE,
    CODE_SCREEN_VALIDATE_SUCCESS,
    CODE_SCREEN_VALIDATE_FAILURE,
    FINAL_CODE_SCREEN_VALIDATE,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_FAILURE,
    SURVEY_SCREEN_LOAD,
    SURVEY_SCREEN_VALIDATE,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_FAILURE,
    PACKAGE_SCREEN_VALIDATE,
    PACKAGE_SCREEN_VALIDATE_SUCCESS,
    PACKAGE_SCREEN_VALIDATE_FAILURE,
    DATA_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    SUMMARY_SCREEN_VALIDATE_SUCCESS,
    FINAL_SUMMARY_SCREEN_VALIDATE,
    FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS,
    FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE,
    PAYMENT_SCREEN_VALIDATE,
    PAYMENT_SCREEN_VALIDATE_SUCCESS,
    PAYMENT_SCREEN_VALIDATE_FAILURE,
    FETCH_CALENDAR_APPOINTMENT_FAILURE,
    SUMMARY_SCREEN_CHANGE_OPTION_FAILURE,
    SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS,
} from '../types';
import Screen from '../screen';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.UserScreen;

export const initialState = {
    loading: false,
    isRecover: false,
    errorRecovery: null,
    loadingPaymentStatus: false,
    screenIds: [Screen.USER],
    error: null,
};

function mainReducer(state = initialState, action) {
    switch (action.type) {
        case PREVIOUS_SCREEN_LOAD: {
            const screenIds = state.screenIds.slice(0, -1);
            return {
                ...state,
                screenIds,
                error: null,
            };
        }
        case NEXT_SCREEN_LOAD: {
            const screenIds = [...state.screenIds, action.payload.screenId];
            return {
                ...state,
                screenIds,
                error: null,
            };
        }
        case SURVEY_SCREEN_LOAD: {
            const screenIds = [...state.screenIds, Screen.SURVEY];
            return {
                ...state,
                screenIds,
                loading: false,
                error: null,
            };
        }
        case SURVEY_SCREEN_VALIDATE:
        case PACKAGE_SCREEN_VALIDATE:
        case CODE_SCREEN_VALIDATE:
        case FINAL_CODE_SCREEN_VALIDATE:
        case FINAL_SUMMARY_SCREEN_VALIDATE:
        case MAIN_SCREEN_SAVE_ORDER:
        case MAIN_SCREEN_GET_ORDER:
            return {
                ...state,
                loading: true,
            };
        case CODE_SCREEN_VALIDATE_FAILURE:
        case FINAL_CODE_SCREEN_VALIDATE_FAILURE:
        case PACKAGE_SCREEN_VALIDATE_FAILURE:
        case SURVEY_SCREEN_VALIDATE_FAILURE:
        case FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE:
        case MAIN_SCREEN_SAVE_ORDER_FAILURE:
        case FETCH_CALENDAR_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case MAIN_SCREEN_GET_OFFER_FAILURE:
        case MAIN_SCREEN_GET_PACKAGE_FAILURE:
        case MAIN_SCREEN_GET_PRODUCT_FAILURE:
        case SUMMARY_SCREEN_CHANGE_OPTION_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case USER_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.CODE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case CODE_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.PACKAGE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.PACKAGE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case PACKAGE_SCREEN_VALIDATE_SUCCESS: {
            const isFinal = state.screenIds.indexOf(Screen.DATA) > -1;
            let screenIds = state.screenIds;
            if (!isFinal) {
                screenIds = [...state.screenIds, Screen.SUMMARY];
            }
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.DATA];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS: {
            return {
                ...state,
                error: null,
            };
        }
        case DATA_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.FINALSUMMARY];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case DATA_SCREEN_VALIDATE_SUCCESS_FINAL: {
            const screenIds = [...state.screenIds, Screen.CODE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.PAYMENT];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.FINALSUMMARY];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case PAYMENT_SCREEN_VALIDATE:
            return {
                ...state,
                loading: true,
                loadingPaymentStatus: true,
            };
        case PAYMENT_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.FINAL];
            return {
                ...state,
                loading: false,
                loadingPaymentStatus: false,
                screenIds,
                error: null,
            };
        }
        case PAYMENT_SCREEN_VALIDATE_FAILURE: {
            const screenIds = state.screenIds.slice(0, -1);
            return {
                ...state,
                loading: false,
                loadingPaymentStatus: false,
                screenIds,
            };
        }
        case MAIN_SCREEN_SAVE_ORDER_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.SAVE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case MAIN_SCREEN_GET_ORDER_FAILURE: {
            const { code } = action.payload;
            const screenIds = [Screen.USER];
            let errorRecovery;
            // 1: orderStatus === 'FINALIZED'
            // 2: expired link
            if (code === 1) {
                errorRecovery = Wording.invalidLinkFinalized;
            } else if (code === 2) {
                errorRecovery = Wording.invalidLink;
            }
            return {
                ...state,
                screenIds,
                errorRecovery,
                loading: false,
            };
        }
        case MAIN_SCREEN_GET_ORDER_SUCCESS: {
            const { order } = action.payload;
            const checkPoinOfDelivery = order.contracts.find(
                c => c.deliveryPoint.pointOfDelivery
            );
            const screenIds =
                checkPoinOfDelivery !== undefined
                    ? [
                          Screen.USER,
                          Screen.CODE,
                          Screen.PACKAGE,
                          Screen.SUMMARY,
                          Screen.DATA,
                          Screen.FINALSUMMARY,
                      ]
                    : [
                          Screen.USER,
                          Screen.SURVEY,
                          Screen.CODE,
                          Screen.PACKAGE,
                          Screen.SUMMARY,
                          Screen.DATA,
                          Screen.CODE,
                      ];
            return {
                ...state,
                isRecover: true,
                loading: false,
                screenIds,
                error: null,
            };
        }
        case USER_SCREEN_RESET_RECOVER: {
            return {
                ...state,
                isRecover: false,
                errorRecovery: null,
            };
        }
        default:
            return state;
    }
}

export default mainReducer;
