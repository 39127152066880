import {
    USER_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
} from '../types';

export const initialState = {
    userType: '',
    energyTypes: [],
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_SCREEN_VALIDATE_SUCCESS:
            return {
                ...state,
                userType: action.payload.userType,
                energyTypes: action.payload.energyTypes,
            };
        case MAIN_SCREEN_GET_ORDER_SUCCESS:
            const { order } = action.payload;
            return {
                ...state,
                userType: order.customer.type,
                energyTypes: order.contracts.map(a => a.energy),
            };
        default:
            return state;
    }
}

export default userReducer;
