import { combineEpics, ofType } from 'redux-observable';

import { of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import {
    PAYMENT_SCREEN_LOAD,
    PAYMENT_SCREEN_VALIDATE,
    PAYMENT_SCREEN_VALIDATE_SUCCESS,
    PAYMENT_SCREEN_VALIDATE_FAILURE,
} from '../types';

const validatePaymentScreen = payload => ({
    type: PAYMENT_SCREEN_VALIDATE,
    payload,
});

const validatePaymentScreenSuccess = payload => ({
    type: PAYMENT_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validatePaymentScreenFailure = payload => ({
    type: PAYMENT_SCREEN_VALIDATE_FAILURE,
    payload,
});

const loadPaymentScreen = payload => ({
    type: PAYMENT_SCREEN_LOAD,
    payload,
});

const preparePayload = ({ reference }) => ({
    orderNumber: reference,
});

const mapGetPayment = (action, { apiRequest }) => {
    const payload = preparePayload(action.payload);
    return apiRequest({
        path: '/getOrderStatus',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.orderStatus) {
                if (
                    response.orderStatus === 'FINALIZED' ||
                    response.orderStatus === 'FINALIZED_WITHOUT_DOC'
                ) {
                    return of(validatePaymentScreenSuccess(response));
                }
            }
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        catchError(error =>
            of(
                validatePaymentScreenFailure({
                    error: error.message,
                })
            )
        )
    );
};

const validatePaymentScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(PAYMENT_SCREEN_VALIDATE),
        mergeMap(action => mapGetPayment(action, dependency))
    );

const paymentScreenEpic = combineEpics(validatePaymentScreenEpic);

export {
    validatePaymentScreen,
    validatePaymentScreenSuccess,
    paymentScreenEpic,
    loadPaymentScreen,
};
