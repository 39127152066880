import WordingConstant from './wording';

export const Wording = WordingConstant.Price;
export const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// const debug = process.env.NODE_ENV !== 'production';
const debug = true;

const log = (title, message) => {
    if (debug) {
        console.log(title, message);
    }
};

const areListEqual = (arr1, arr2) => {
    if (
        arr1 === undefined ||
        arr2 === undefined ||
        arr1.length !== arr2.length
    ) {
        return false;
    }
    const arr = arr1.filter(e => !arr2.includes(e));
    return arr.length === 0;
};

const sendImage = (choiceType, value, imageSrcOn, imageSrc) => {
    if (typeof choiceType === 'string') return imageSrc;
    if (areListEqual(choiceType, value)) return imageSrcOn;
    return imageSrc;
};

const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

const isWeekend = date => {
    const dt = new Date(date);
    return dt.getDay() === 6 || dt.getDay() === 0;
};

const getDateString = date => {
    const day = `${date.date < 10 ? `0${date.date}` : date.date}`;
    const month = `${
        date.month + 1 < 10 ? `0${date.month + 1}` : date.month + 1
    }`;
    return `${date.year}-${month}-${day}`;
};

const getTodayDateString = () => {
    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();
    return getDateString({
        date,
        month,
        year,
    });
};

const dateDiffDays = date => {
    const today = new Date().toISOString().split('T')[0];
    const d1 = new Date(today);
    const d2 = new Date(date);
    return Math.floor((d1 - d2) / _MS_PER_DAY);
};

const handleConditionEmail = value => {
    const regex = /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(value).toLowerCase());
};

const handleConditionPhone = value => {
    const regex = /^((06)|(07))[0-9]{8}$/;
    return regex.test(String(value));
};

const mergeArray = (arr1, arr2, key) => {
    if (arr1 === undefined || arr1.length === 0) {
        return arr2;
    }
    if (arr2 === undefined || arr2.length === 0) {
        return arr1;
    }
    const arr = [...arr1];
    const temp = arr.map(a => a[key]);
    arr2.forEach(a => {
        if (temp.indexOf(a[key]) < 0) {
            arr.push(a);
        }
    });
    return arr;
};

const addBusinessDays = days => {
    let nDays = days;
    const now = new Date();
    const dayOfTheWeek = now.getDay();
    const deliveryDay = dayOfTheWeek + days;
    let calendarDays = days;
    let deliveryWeeks;
    if (deliveryDay >= 6) {
        nDays -= 6 - dayOfTheWeek;
        calendarDays += 2;
        deliveryWeeks = Math.floor(nDays / 5);
        calendarDays += deliveryWeeks * 2;
    }
    now.setTime(now.getTime() + calendarDays * 24 * 60 * 60 * 1000);
    return now;
};

const transformLabel = label => {
    if (label === Wording.totalhour.label) {
        return Wording.totalhour.text;
    }
    if (label === Wording.high.label) {
        return Wording.high.text;
    }
    if (label === Wording.low.label) {
        return Wording.low.text;
    }
    if (label === Wording.paymentFrequency.label) {
        return Wording.paymentFrequency.text;
    }
    return label;
};

const displayPhone = phone => {
    if (phone.startsWith('+33')) {
        return `0${phone.substring(3)}`;
    }
    return phone;
};

export {
    log,
    areListEqual,
    sendImage,
    addDays,
    isWeekend,
    getDateString,
    getTodayDateString,
    handleConditionEmail,
    handleConditionPhone,
    mergeArray,
    addBusinessDays,
    dateDiffDays,
    transformLabel,
    displayPhone,
};
