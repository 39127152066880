import {
    PACKAGE_SCREEN_VALIDATE_SUCCESS,
    SUMMARY_SCREEN_VALIDATE_SUCCESS,
    SUMMARY_SCREEN_CHANGE_OFFER,
    SUMMARY_SCREEN_CHANGE_OPTION,
    SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS,
    SUMMARY_SCREEN_CHANGE_OPTION_FAILURE,
    SUMMARY_SCREEN_GET_SPONSOR,
    FINAL_SUMMARY_SCREEN_VALIDATE,
    FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS,
    FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES,
    FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES,
    CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    SURVEY_SCREEN_LOAD,
} from '../types';

// options: list of product.productCode
// additionalOptions: list of additionalRate.label
const defaultValue = {
    offer: {},
    loadingOption: false,
    installmentFrequency: '',
    chosenPackages: [],
    packageID: '',
    cyclicBill: false,
    options: [],
    additionalOptions: [],
    error: null,
};

export const initialState = {
    EL: { ...defaultValue },
    NG: { ...defaultValue },
    offers: [],
    products: [],
    contracts: [],
    parenting: {
        code: '',
        role: 'SPONSOR',
    },
};

const recoverState = (state, { order, products, offers, packages }) => {
    const { contracts } = order;
    const modifiedState = state;
    contracts.forEach(contract => {
        const adtRates =
            contract.chosenProduct.additionalRates &&
            contract.chosenProduct.additionalRates
                .filter(
                    ({ active, type }) => active === true && type === 'OPTION'
                )
                .map(({ label }) => label);
        modifiedState[contract.energy].additionalOptions = adtRates;
        modifiedState[contract.energy].chosenPackages = contract.chosenPackages;
        modifiedState[contract.energy].installmentFrequency =
            contract.installmentFrequency;
        const offer = offers.find(off => off.energy === contract.energy) || {};
        modifiedState[contract.energy].offer = offer;
        const pkg = packages.find(
            p => p.energy === contract.energy && p.recommended
        );
        modifiedState[contract.energy].packageID = (pkg || {}).id || '';
        modifiedState[contract.energy].options = contract.chosenProduct.default
            ? []
            : [contract.chosenProduct.productCode];
    });

    return {
        ...modifiedState,
        contracts,
        offers,
        products,
    };
};

function summaryReducer(state = initialState, action) {
    switch (action.type) {
        case PACKAGE_SCREEN_VALIDATE_SUCCESS: {
            const { EL, NG, offers, products } = action.payload;
            return {
                ...state,
                products,
                offers,
                EL: {
                    ...EL,
                    options: [],
                    additionalOptions: [],
                    error: null,
                },
                NG: {
                    ...NG,
                    options: [],
                    additionalOptions: [],
                    error: null,
                },
            };
        }
        case SURVEY_SCREEN_LOAD:
        case CODE_SCREEN_VALIDATE_SUCCESS: {
            return {
                ...state,
                contracts: action.payload.contracts,
            };
        }
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS: {
            const { contracts, packages } = action.payload;
            const modifiedContracts = contracts.map(contract => {
                const currentContract = state.contracts.find(
                    c => c.energy === contract.energy
                );
                const notNullEstimate = contract.estimates.find(
                    es => es.quantity > 0
                );
                const estimates = notNullEstimate
                    ? contract.estimates
                    : currentContract.estimates;
                return {
                    ...currentContract,
                    estimates,
                    deliveryPoint: contract.deliveryPoint,
                    rateOption: contract.rateOption,
                };
            });
            const res = {
                EL: { ...state.EL },
                NG: { ...state.NG },
            };
            if (packages) {
                res.EL.packageID =
                    (
                        packages.find(
                            p => p.energy === 'EL' && p.recommended
                        ) || {}
                    ).id || '';
                res.NG.packageID =
                    (
                        packages.find(
                            p => p.energy === 'NG' && p.recommended
                        ) || {}
                    ).id || '';
            }
            return {
                ...state,
                ...res,
                contracts: modifiedContracts,
            };
        }
        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const { contracts } = action.payload;
            let modifiedContracts = contracts;
            if (state.contracts.length > 0) {
                modifiedContracts = state.contracts.map(contract => {
                    const incomingContract =
                        contracts.find(c => c.energy === contract.energy) || {};
                    const deliveryPoint = {
                        ...contract.deliveryPoint,
                        deliveryPointCategory:
                            incomingContract.deliveryPoint
                                .deliveryPointCategory,
                        gridRate: incomingContract.deliveryPoint.gridRate,
                        profile: incomingContract.deliveryPoint.profile,
                    };
                    const estimates = incomingContract.estimates.map(es => {
                        if (es.quantity === 0) {
                            return (
                                contract.estimates.find(
                                    e => e.timeframe === es.timeframe
                                ) || es
                            );
                        }
                        return es;
                    });
                    return {
                        ...contract,
                        deliveryPoint,
                        estimates,
                    };
                });
            }
            return {
                ...state,
                contracts: modifiedContracts,
            };
        }
        case SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            const p = state;
            p.EL.error = null;
            p.NG.error = null;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OFFER: {
            const p = state;
            const { offer, energyType, options } = action.payload;
            p[energyType].offer = offer;
            p[energyType].options = options;
            p[energyType].error = null;
            return {
                ...p,
            };
        }
        case FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES: {
            const p = state;
            const { packages, packageID, energyType } = action.payload;
            p[energyType].chosenPackages = packages;
            p[energyType].packageID = packageID;
            return {
                ...p,
            };
        }
        case FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES: {
            const p = state;
            const { additionalOptions, energyType } = action.payload;
            p[energyType].additionalOptions = additionalOptions;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION: {
            const p = state;
            const { energyType } = action.payload;
            p[energyType].loadingOption = true;
            p[energyType].error = null;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS: {
            const p = state;
            const { energyType, offers } = action.payload;
            p[energyType].loadingOption = false;
            p[energyType].offer = action.payload[energyType].offer;
            p[energyType].options = [
                action.payload[energyType].offer.product.productCode,
            ];
            p[energyType].error = null;
            return {
                ...p,
                offers: [...state.offers, ...offers],
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION_FAILURE: {
            const p = state;
            const { energyType, error } = action.payload;
            p[energyType].loadingOption = false;
            p[energyType].options = [];
            p[energyType].error = error;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_GET_SPONSOR: {
            return {
                ...state,
                parenting: {
                    code: action.payload.sponsorId,
                    role: 'SPONSOR',
                },
            };
        }
        case FINAL_SUMMARY_SCREEN_VALIDATE: {
            return {
                ...state,
            };
        }
        case FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            return {
                ...state,
                parenting: action.payload.parenting,
            };
        }

        case MAIN_SCREEN_GET_ORDER_SUCCESS:
            return recoverState(state, action.payload);

        default:
            return state;
    }
}

export default summaryReducer;
