import React from 'react';
import { connect } from 'react-redux';
import SearchService from '../../utils/search';

import Screen from '../screen';
import WordingConstant from '../../utils/wording.json';
import FinalSummaryContainer from '../../containers/FinalSummaryContainer';
import SwitchContainer from '../../containers/SwitchContainer';

import * as actions from './FinalSummaryScreen.actions';
import * as actionsSummary from '../SummaryScreen/SummaryScreen.actions';

const Wording = WordingConstant.FinalSummaryScreen;
const SurveyWording = WordingConstant.SurveyScreen;
const DataWording = WordingConstant.DataScreen;

export class FinalSummaryScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.PAYMENT,
        previousScreen: Screen.DATA,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.FINALSUMMARY,
        showSaveButton: true,
        step: 4,
        validate: () => {},
    };

    constructor(props) {
        super(props);
        const { summaryState } = props;
        const initialFields = {
            additionalOptions: [],
            packageID: '',
            prices: [],
            lastPackageID: '',
            lastPrices: [],
        };
        this.state = {
            toggle: {
                showInput: summaryState.parenting.code !== '',
            },
            fields: {
                EL: {
                    ...initialFields,
                    additionalOptions: summaryState.EL.additionalOptions,
                },
                NG: {
                    ...initialFields,
                    additionalOptions: summaryState.NG.additionalOptions,
                },
                thirdParty: {
                    code: summaryState.parenting.code,
                    message: '',
                    error: false,
                    loading: false,
                },
            },
        };

        this.searchService = new SearchService();
    }

    componentDidMount() {
        FinalSummaryScreen.navigationOptions.validate = () => {
            const {
                fields: { thirdParty },
            } = this.state;
            const {
                userState,
                dataState,
                summaryState,
                paymentState,
                validateFinalSummaryScreenDispatch,
            } = this.props;
            return validateFinalSummaryScreenDispatch({
                ...userState,
                ...dataState,
                ...summaryState,
                ...paymentState,
                thirdParty,
            });
        };

        this.searchService.getParentingResult().subscribe(res => {
            const { fields } = this.state;
            fields.thirdParty = {
                ...fields,
                code: fields.thirdParty.code,
                message: res.message,
                error: res.code === 1,
                loading: false,
            };
            this.setState({ fields });
        });

        const { handleGoNext } = this.props;
        if (handleGoNext) {
            handleGoNext(true);
        }
    }

    componentWillUnmount() {
        this.searchService.unsubscribe();
    }

    handleToggle = ({ target: { value, checked } }, type) => {
        const {
            userState: { userType },
            summaryState,
            changeOfferDispatch,
            changeProductOptionDispatch,
        } = this.props;
        const offer = summaryState.offers.find(
            off =>
                off.energy === type &&
                (checked ? !off.product.default : off.product.default)
        );
        if (offer) {
            const options = checked ? [value] : [];
            changeOfferDispatch({
                offer,
                options,
                energyType: type,
            });
        } else {
            const productCode = checked
                ? value
                : (
                      summaryState.products.find(
                          p => p.energy === type && p.default
                      ) || {}
                  ).productCode || '';
            changeProductOptionDispatch({
                ...summaryState,
                userType,
                productCode,
                energyType: type,
                contracts: summaryState.contracts.filter(
                    p => p.energy === type
                ),
            });
        }
    };

    handleToggleAdditionalOptions = ({ target: { value } }, type) => {
        const { fields } = this.state;
        const currentOptions = fields[type].additionalOptions;
        if (currentOptions.includes(value)) {
            fields[type].additionalOptions = currentOptions.filter(
                option => option !== value
            );
        } else {
            currentOptions.push(value);
        }
        const { changeAdditionalRatesDispatch } = this.props;
        changeAdditionalRatesDispatch({
            additionalOptions: fields[type].additionalOptions,
            energyType: type,
        });
        this.setState({ fields });
    };

    handleChangeFields = (prices, packageID, energy) => {
        const { fields } = this.state;
        fields[energy].prices = prices;
        fields[energy].packageID = packageID;

        const {
            changePackagesDispatch,
            packageState,
            summaryState,
        } = this.props;
        let packages = [];
        let id = packageID;
        if (packageID === '') {
            fields[energy].lastPackageID = packageID;
            id = summaryState[energy].packageID;
            const currentPackage = packageState.packages.find(p => p.id === id);
            if (currentPackage) {
                packages = this.findPackages(
                    currentPackage.authorizedPackages,
                    prices
                );
            }
        } else {
            fields[energy].lastPrices = prices;
            packages = packageState.packages
                ? packageState.packages.filter(
                      p => p.energy === energy && p.id === packageID
                  )
                : [];
        }
        changePackagesDispatch({ packages, packageID: id, energyType: energy });
        this.setState({ fields });
    };

    handleResetFields = (name, energy) => {
        const { fields } = this.state;
        if (name === 'packageID') {
            fields[energy].packageID = fields[energy].lastPackageID || '';
        } else {
            fields[energy].prices = fields[energy].lastPrices || [];
        }
        this.setState({ fields });
    };

    findPackages = (packages, couple) => {
        const res = (packages &&
            packages.find(
                p =>
                    p &&
                    p.length === 2 &&
                    (p[0].amount === `${couple[0]}` &&
                        p[1].amount === `${couple[1]}`)
            )) || [{}, {}];
        return res;
    };

    getDetail = energy => {
        const {
            codeState: { PDL, PCE },
            dataState: {
                common: { dateContract },
            },
            summaryState,
        } = this.props;
        const details = [];
        if (energy === 'EL' && PDL !== '') {
            details.push({
                title: Wording.changeProviderPDL,
                value: PDL,
            });
        } else if (energy === 'NG' && PCE !== '') {
            details.push({
                title: Wording.changeProviderPCE,
                value: PCE,
            });
        }
        const { date, month, year } = dateContract[energy];
        const dayLabel = `${date < 10 ? `0${date}` : date}`;
        const monthLabel = `${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
        details.push({
            title: Wording.beginDate,
            value: `${dayLabel}/${monthLabel}/${year}`,
        });
        const contract =
            summaryState.contracts &&
            summaryState.contracts.find(c => c && c.energy === energy);
        if (contract === undefined) {
            return details;
        }
        const { deliveryPoint } = contract;
        const readingFrequency = (
            Wording.frequency.values.find(
                v => v.value === deliveryPoint.readingFrequency
            ) || {}
        ).text;

        const installmentFrequency = (
            Wording.frequency.values.find(
                v => v.value === summaryState[energy].installmentFrequency
            ) || {}
        ).text;
        // The settlement frequency will be replaced if contract contains it.
        // const settlementFrequency = (
        //     Wording.frequency.values.find(
        //         v => v.value === contract.settlementFrequency
        //     ) || {}
        // ).text;
        const settlementFrequency = summaryState[energy].cyclicBill
            ? 'Mensuelle ou bimestrielle'
            : 'Semestrielle';
        let separateDetail;
        if (energy === 'EL') {
            const priceOption = (
                SurveyWording.priceOption.values.find(
                    v => v.value === contract.rateOption
                ) || {}
            ).text;
            separateDetail = [
                {
                    title: Wording.contractualCapacityEL,
                    value: `${deliveryPoint.contractualCapacity} ${
                        deliveryPoint.contractualCapacityUnit
                    }`,
                },
                {
                    title: Wording.meterReading,
                    value: deliveryPoint.meterNumber,
                },
                {
                    title: Wording.priceOption,
                    value: priceOption,
                },
            ];
        } else {
            separateDetail = [
                {
                    title: Wording.profile,
                    value: deliveryPoint.profile,
                },
                {
                    title: Wording.meterReading,
                    value: deliveryPoint.meterNumber,
                },
                {
                    title: Wording.priceOption,
                    value: deliveryPoint.gridRate,
                },
            ];
            if (contract.estimates && contract.estimates.length > 0) {
                separateDetail.unshift({
                    title: Wording.contractualCapacityNG,
                    value: `${contract.estimates[0].quantity} ${
                        Wording.contractualCapacityUnitNG
                    }`,
                });
            }
        }
        details.push(
            ...separateDetail,
            ...[
                {
                    title: Wording.readingFrequency,
                    value: readingFrequency,
                },
                {
                    title: Wording.installmentFrequency,
                    value: installmentFrequency,
                },
                {
                    title: Wording.settlementFrequency,
                    value: settlementFrequency,
                },
            ]
        );
        return details;
    };

    transformAuthorizedPackages = packages => {
        const res =
            packages &&
            packages
                .map(p => {
                    if (p && p.length === 2) {
                        return p
                            .sort((x, y) =>
                                x.timeframe < y.timeframe ? -1 : 1
                            )
                            .map(x => parseInt(x.amount, 0));
                    }
                    return [0, 0];
                })
                .sort((x, y) => (x[0] < y[0] ? -1 : 1));
        return res;
    };

    handleChangeInputParenting = ({ target: { value } }) => {
        const { fields } = this.state;
        fields.thirdParty = {
            ...fields.thirdParty,
            code: value,
        };
        if (value.length > 9) {
            fields.thirdParty.loading = true;
            this.searchService.search(value);
        }
        this.setState({ fields });
    };

    renderBody(energy, index) {
        const {
            userState: { energyTypes },
            summaryState,
            packageState,
        } = this.props;
        const { fields } = this.state;
        const s = summaryState[energy];
        const offer = s.offer;
        const productOptions = s.options;
        const details = this.getDetail(energy);
        const additionalRatesList =
            (offer &&
                offer.product &&
                offer.product.additionalRates &&
                offer.product.additionalRates.filter(
                    a => a.type === 'OPTION'
                )) ||
            [];
        const packages = packageState.packages
            ? packageState.packages.filter(p => p.energy === energy)
            : [];
        const prices = s.chosenPackages
            ? s.chosenPackages.map(p => p.amount)
            : [];
        const products = summaryState.products
            ? summaryState.products.filter(
                  product => !product.default && product.energy === energy
              )
            : [];
        const currentPackage =
            packages.find(p => p.id === summaryState[energy].packageID) ||
            packages.find(p => p.recommended) ||
            {};
        const ranges = this.transformAuthorizedPackages(
            currentPackage.authorizedPackages
        );

        return (
            <FinalSummaryContainer
                key={index}
                prices={prices}
                offer={offer}
                cyclicBill={summaryState[energy].cyclicBill}
                currentPackage={currentPackage}
                packages={packages}
                ranges={ranges}
                products={products}
                currentOptions={productOptions}
                currentAdditionalOptions={fields[energy].additionalOptions}
                options={additionalRatesList}
                handleToggle={e => this.handleToggle(e, energy)}
                handleToggleAdditionalOptions={e =>
                    this.handleToggleAdditionalOptions(e, energy)
                }
                handleChangeFields={(ps, id) =>
                    this.handleChangeFields(ps, id, energy)
                }
                handleResetFields={name => this.handleResetFields(name, energy)}
                loadingOption={s.loadingOption}
                energy={energy}
                details={details}
                showSeparator={index + 1 < energyTypes.length}
            />
        );
    }

    renderHeader() {
        const {
            dataState: {
                common: { civility, name, surname, address, newAddress },
            },
        } = this.props;
        const civilityLabel = (
            DataWording.civility.values.find(v => v.value === civility) || {}
        ).label;
        const person = {
            name: `${civilityLabel} ${name} ${surname}`,
            address: `${newAddress.address ||
                address.address} ${newAddress.postalCode ||
                address.postalCode} ${newAddress.city || address.city}`,
        };
        return (
            <div className="final-summary-screen__header">
                <p>{person.name}</p>
                <p>{person.address}</p>
            </div>
        );
    }

    renderSwitch() {
        const {
            toggle: { showInput },
            fields: { thirdParty },
        } = this.state;
        const switchProps = {
            showInput,
            toggleTitle: Wording.parenting.title,
            thirdParty: {
                value: thirdParty.code,
                message: thirdParty.message,
                error: thirdParty.error,
                loading: thirdParty.loading,
            },
            description: Wording.parenting.description,
            handleShowInput: () => {
                const { toggle, fields } = this.state;
                toggle.showInput = !toggle.showInput;
                if (!toggle.showInput) {
                    fields.thirdParty = {
                        code: '',
                        message: '',
                        error: false,
                        loading: false,
                    };
                }
                this.setState({ toggle, fields });
            },
            handleChangeInput: this.handleChangeInputParenting,
        };
        return <SwitchContainer {...switchProps} />;
    }

    render() {
        const {
            userState: { energyTypes },
        } = this.props;
        return (
            <div className="final-summary-screen">
                {this.renderHeader()}
                {energyTypes &&
                    energyTypes.map((energy, index) =>
                        this.renderBody(energy, index)
                    )}
                {this.renderSwitch()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    codeState: state.codeReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
    dataState: state.dataReducer,
    paymentState: state.paymentReducer,
});

const mapDispatchToProps = dispatch => ({
    validateFinalSummaryScreenDispatch: payload =>
        dispatch(actions.validateFinalSummaryScreen(payload)),
    changePackagesDispatch: payload =>
        dispatch(actions.changePackages(payload)),
    changeAdditionalRatesDispatch: payload =>
        dispatch(actions.changeAdditionalRates(payload)),
    changeProductOptionDispatch: payload =>
        dispatch(actionsSummary.changeProductOption(payload)),
    changeOfferDispatch: payload =>
        dispatch(actionsSummary.changeOffer(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FinalSummaryScreen);
