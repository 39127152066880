import React from 'react';

import SummaryElement from '../../components/SummaryElement';
import PlanElement from '../../components/PlanElement';

import WordingConstant from '../../utils/wording.json';

const SummaryWording = WordingConstant.SummaryScreen;

const SummaryContainer = ({
    prices,
    currentOptions,
    handleToggle,
    paymentFrequency,
    offer,
    products,
    energy,
    loadingOption,
    showSeparator,
}) => (
    <div className="summary-container">
        <h3>{SummaryWording.subtitle[energy]}</h3>
        <div className="summary-container__element">
            <PlanElement
                prices={prices}
                offer={offer}
                products={products}
                handleToggle={handleToggle}
                idToggle={energy}
                currentOptions={currentOptions}
                loadingOption={loadingOption}
            />
            <SummaryElement
                offer={offer}
                paymentFrequency={paymentFrequency}
                loadingOption={loadingOption}
            />
        </div>
        {showSeparator && <hr />}
    </div>
);

export default SummaryContainer;
