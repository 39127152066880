import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import MainScreen from './screens/MainScreen';
import * as serviceWorker from './serviceWorker';
import store from './store';

import { version } from '../package.json';
import { log } from './utils/helpers';

import './index.css';

const App = () => (
    <Provider store={store}>
        <MainScreen />
    </Provider>
);

log('Version : ', version);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    const siteWidth = 280;

    const initialScale = window.screen.availWidth / 980;

    document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
            'content',
            `width=${siteWidth}, initial-scale=${initialScale}`
        );
}
