import React from 'react';

const ImageElement = ({ isChecked, text, imageSrc, onClick }) => (
    <button
        type="button"
        className={`image-element ${isChecked ? 'image-element--checked' : ''}`}
        onClick={onClick}
    >
        <p className="image-element__text">{text}</p>
        <img src={imageSrc} alt="icon" />
    </button>
);

export default ImageElement;
