import React from 'react';
import {
    CustomInput,
    CustomRadio,
    CustomSelect,
    ImageElement,
    CustomDropdownInput,
} from '../../components';
import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.SurveyScreen;

const SurveyContainer = ({
    fields: {
        city,
        postalCode,
        housingType,
        residenceType,
        heatingType,
        priceOption,
        area,
        inhabitantsNumber,
    },
    setting: { postalCodes, isLoadingPC, codeText, showError },
    handleToggle,
    handleSelect,
    handleInputCity,
    handleInputChangeAddressContainer,
    handleChangeDropdownSelect,
    onFocusDropdown,
    showPriceOption,
    condition,
}) => {
    const renderSelectArea = () => {
        const {
            value: { start },
        } = Wording.area;
        const {
            value: { length },
        } = Wording.area;
        const {
            value: { step },
        } = Wording.area;
        const list = Array.from({ length }, (v, k) => {
            if (k === 0) {
                return { value: 20, label: '0 à 20' };
            }
            const s = start + step * (k + 1) + 1;
            const ss = start + step * (k + 2);
            return { value: ss, label: `${s} à ${ss}` };
        });
        return (
            <CustomSelect
                id="idArea"
                value={area}
                defaultValue={area}
                text={Wording.area.title}
                options={list}
                handleChange={option => handleSelect(option, 'area')}
            />
        );
    };

    const renderSelectInhabitants = () => {
        const {
            value: { start },
        } = Wording.inhabitantsNumber;
        const {
            value: { length },
        } = Wording.inhabitantsNumber;
        const {
            value: { step },
        } = Wording.inhabitantsNumber;
        const list = Array.from({ length }, (v, k) => {
            const s = start + step * k;
            return { value: s, label: `${s}` };
        });
        return (
            <CustomSelect
                id="idInhabitantsNumber"
                value={inhabitantsNumber}
                defaultValue={inhabitantsNumber}
                text={Wording.inhabitantsNumber.title}
                options={list}
                handleChange={option =>
                    handleSelect(option, 'inhabitantsNumber')
                }
            />
        );
    };

    const renderSelector = (
        { title, values },
        choiceType,
        handleClick,
        error
    ) => (
        <div className="survey-container__selector">
            <label htmlFor={`id${title}`}>{title}</label>
            <div className="survey-container__selector__container">
                <div className="survey-container__selector__container__images">
                    {values &&
                        values.map(
                            ({ text, value, imageSrc, imageSrcOn }, index) => (
                                <ImageElement
                                    key={index}
                                    isChecked={choiceType === value}
                                    text={text}
                                    imageSrc={
                                        imageSrcOn && choiceType === value
                                            ? resources[imageSrcOn]
                                            : resources[imageSrc]
                                    }
                                    onClick={() => handleClick(value)}
                                />
                            )
                        )}
                </div>
                {error && error !== '' && (
                    <p className="survey-container__selector__container__error">
                        {error}
                    </p>
                )}
            </div>
        </div>
    );

    const renderRadio = ({ title, values }, option, handleCheck, error) => (
        <div className="survey-container__selector">
            <label htmlFor={`id${title}`}>{title}</label>
            <div className="survey-container__selector__container">
                <div className="survey-container__selector__container__custom-radio">
                    {values &&
                        values.map(({ id, text, value }, index) => (
                            <CustomRadio
                                key={index}
                                id={id}
                                text={text}
                                value={value}
                                checked={option === value}
                                handleCheck={() => handleCheck(value)}
                            />
                        ))}
                </div>
                {error && error !== '' && (
                    <p className="survey-container__selector__container__error">
                        {error}
                    </p>
                )}
            </div>
        </div>
    );

    return (
        <div className="survey-container">
            <CustomDropdownInput
                text={Wording.postalCode.title}
                inputValue={
                    codeText.length <= 5 ? codeText : codeText.substring(0, 5)
                }
                onFocus={onFocusDropdown}
                onInputChange={handleInputChangeAddressContainer}
                options={postalCodes}
                placeholder={postalCode}
                handleChange={handleChangeDropdownSelect}
                value={{ label: postalCode }}
                isLoading={isLoadingPC}
                error={
                    (postalCode === '' || postalCode === null) &&
                    showError &&
                    Wording.error.mainText
                }
            />
            <CustomInput
                text={Wording.city.title}
                placeholder={Wording.city.title}
                type="text"
                value={city}
                onInputChange={handleInputCity}
                isLocked
            />
            {renderSelector(
                Wording.housingType,
                housingType,
                e => handleToggle(e, 'housingType'),
                housingType === '' && showError ? Wording.error.mainText : null
            )}
            {renderSelector(
                Wording.residenceType,
                residenceType,
                e => handleToggle(e, 'residenceType'),
                residenceType === '' && showError
                    ? Wording.error.mainText
                    : null
            )}
            {renderSelectArea()}
            {renderSelectInhabitants()}
            {renderSelector(
                Wording.heatingType,
                heatingType,
                e => handleToggle(e, 'heatingType'),
                heatingType === '' && showError ? Wording.error.mainText : null
            )}

            {showPriceOption &&
                renderRadio(
                    Wording.priceOption,
                    priceOption,
                    e => handleToggle(e, 'priceOption'),
                    priceOption === '' && showError
                        ? Wording.error.mainText
                        : null
                )}

            {showError &&
                !condition({
                    city,
                    postalCode,
                    housingType,
                    residenceType,
                    heatingType,
                    priceOption,
                    area,
                    inhabitantsNumber,
                }) && (
                    <p className="survey-container__error">
                        {Wording.error.bottomText}
                    </p>
                )}
        </div>
    );
};

export default SurveyContainer;
